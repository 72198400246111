export const concatenate = (
  firstName: string,
  lastName: string,
  startTime: string,
  endTime: string,
  date?: string
) => {
  const time = `${startTime}-${endTime}`;
  const fullName = `${firstName} ${lastName}`;
  return date ? `${date} ${fullName} ${time}` : `${time} ${fullName}`;
};
