import { styled, TextField } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledTextField = styled(TextField)({
  "& input:disabled": {
    backgroundColor: `${COLORS.GRAY.GRAY_MEDIUM}`,
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "30px",
    paddingLeft: "2rem",
    "&.Mui-focused fieldset": {
      borderColor: "#a9b9a6d6",
    },
    "&.Mui-focused ": {
      borderColor: "#a9b9a6d6",
    },
  },
});
