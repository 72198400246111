import { useTranslation } from "react-i18next";
import { INext } from "./NextFollowUp.type";
import { Grid, Tooltip, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { selectCurrentRole } from "../../redux/slices/UserAuth";
import { useSelector } from "react-redux";
import { FollowUpActions } from "../FollowUpActions/FollowUpActions";
import { useState } from "react";
import { ScheduleFollowUp } from "../ScheduleFollowUp/ScheduleFollowUp";
import { ActionButton, StyledTitle } from "./NextFollowUp.style";
import { COLORS } from "../../core/constants/colors";
import { GlobalVlariables } from "../../core/constants/constant";
export const NextFollowUp = (props: INext) => {
  const { t } = useTranslation();
  const role = useSelector(selectCurrentRole);
  //Schedule follow up
  const [schedule, setSchedule] = useState(false);
  const closeSchedule = () => {
    setSchedule(false);
  };
  if (props.followUpId) {
    return (
      <>
        <Grid container>
          <Grid item md={12} xs={12}>
            <StyledTitle>{t("next_follow_up")}</StyledTitle>
          </Grid>
          <Grid item md={4} xs={6}>
            <Typography>{props?.date}</Typography>
          </Grid>
          <Grid item md={5} xs={6}>
            <Tooltip title={t("follow_up.status.waiting")}>
              <FiberManualRecordIcon color="warning" fontSize="small" />
            </Tooltip>
          </Grid>

          {role == GlobalVlariables.ROLE.DOCTOR && (
            <Grid item md={3} xs={7}>
              <FollowUpActions
                patientId={props.patientId ? props.patientId : -1}
                followUpId={props.followUpId ? props.followUpId : -1}
                isNextFollowUp={true}
              />
            </Grid>
          )}
        </Grid>

        <ScheduleFollowUp
          open={schedule}
          onClose={closeSchedule}
          patientId={props.patientId ? props.patientId : -1}
        />
      </>
    );
  }
  return (
    <>
      {role == GlobalVlariables.ROLE.PATIENT && (
        <Grid container>
          <Grid item md={12} xs={12}>
            <StyledTitle>{t("next_follow_up")}</StyledTitle>
          </Grid>
          <Grid item md={12} xs={12}>
            <div>{t("no_next")}</div>
          </Grid>
        </Grid>
      )}
      {role == GlobalVlariables.ROLE.DOCTOR && (
        <>
          <Grid container>
            <Grid item md={12} xs={12}>
              <StyledTitle>{t("next_follow_up")}</StyledTitle>
            </Grid>
            <Grid item md={12} xs={12}>
              <ActionButton
                textColor={COLORS.GREEN.BOTTLE_GREEN}
                onClick={() => {
                  setSchedule(true);
                }}
              >
                {t("create")}
              </ActionButton>
            </Grid>
          </Grid>
          <ScheduleFollowUp
            open={schedule}
            onClose={closeSchedule}
            patientId={props.patientId ? props.patientId : -1}
          />
        </>
      )}
    </>
  );
};
