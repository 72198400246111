import { Grid, styled, TextField } from "@mui/material";

export const StyledGrid = styled(Grid)({
  textAlign: "center",
  display: "flex",
});

export const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "30px",
    paddingLeft: "2rem",
    "&.Mui-focused fieldset": {
      borderColor: "#a9b9a6d6",
    },
    "&.Mui-focused ": {
      borderColor: "#a9b9a6d6",
    },
  },
});
