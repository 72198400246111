import { AppLayout } from "../../layouts/AppLayout/AppLayout";
import { CreatePatient } from "../../components/CreatePatient/CreatePatient";
import { TableComponent } from "../../components/Table/TableComponent";
import { CentredTable, StyledDiv } from "./Patients.styles";

export const Patients = () => {
  return (
    <AppLayout>
      <CentredTable>
        <TableComponent>
          <CreatePatient />
        </TableComponent>
      </CentredTable>
    </AppLayout>
  );
};
