export const PATHS = {
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/resetpassword",
  HOME: "/",
  PROFIL: "/profil",
  CALENDAR: "/calendar",
  CHAT: "/chat",
  NOTIFICATIONS: "/notifications",
  PATIENT_AUTH: "/patient-auth",
  MY_PATIENTS: "/my-patients",
  MY_PARTNERS: "/my-partners",
  CONVERSATIONS: "/conversations",
  MY_CALENDAR: "/my-calendar",
  SUBSCRIPTION: "/subscription",
  MY_PATIENT_PROFILE: `/patients/:id`,
  GENERATE_PROGRAM: "/generate-program",
};
export const ENDPOINTS = {
  FETCH_PATIENTS: "patient",
  CREATE_PATIENT: "patient/create",
  PROFILE: "profile",
  MAIN: "main",
  HISTORY: "history",
  TRACKING: "tracking",
  FETCH_FOLLOWUPS: "follow-up",
  FOLLOW_UP_CANCEL: "cancel",
  CREATE_PATIENT_FOLLOWUP: "follow-up",
  START_PATIENT_FOLLOWUP: "start-follow-up",
  CREATE_IMMEDIATE_FOLLOWUP: "immediate",
  TASK: "task",
  GENERATE_PROGRAM: "generate-program",
  SEND_PROGRAM: "send-generated-program",
  HOME: "home",
};
export const DOCTOR_BASE_URL = process.env.REACT_APP_API_ENDPOINT
  ? `${process.env.REACT_APP_API_ENDPOINT}/api-doctor/`
  : "https://mydoctiplus.api.pfe.anypli.com/api-doctor/";
export const PATIENT_BASE_URL = process.env.REACT_APP_API_ENDPOINT
  ? `${process.env.REACT_APP_API_ENDPOINT}/api-patient/`
  : "https://mydoctiplus.api.pfe.anypli.com/api-patient";
