import {
  useProgramQuery,
  useSendProgramMutation,
} from "../../redux/api/OpenAiAPi";
import { IGeneratedProgram } from "./GeneratedProgram.type";
import { Loader } from "../loader/Loader";
import SendIcon from "@mui/icons-material/Send";
import {
  DayTitle,
  DownloadSection,
  SectionTitle,
  StyledTextField,
  StyledTitle,
  StyledUl,
} from "./GeneratedProgram.style";
import { Box, Divider, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { COLORS } from "../../core/constants/colors";
import { emailValidation } from "../../core/utils/helpers/emailValidation";
import { dispatchSnackBar } from "../../core/utils/helpers/dispatchSnackBar";

export const GeneratedProgram = (props: IGeneratedProgram) => {
  const { t, i18n } = useTranslation();
  const {
    data: program,
    isLoading,
    isSuccess,
  } = useProgramQuery({ ...props, lang: i18n.language.substring(0, 2) });
  const [
    sendProgram,
    {
      isLoading: sendProgramIsLoading,
      isError: sendProgramIsError,
      isSuccess: sendProgramIsSuccess,
    },
  ] = useSendProgramMutation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleSend = async (e: any) => {
    e.preventDefault();
    setEmailError(
      email.length === 0
        ? (t("error.required") as string)
        : !emailValidation(email)
        ? (t("error.email_validation") as string)
        : ""
    );
    if (emailError.length === 0) {
      try {
        const response = await sendProgram({
          email: email,
          inputs: JSON.stringify(props).replace(/\\/g, ""),
          program: JSON.stringify(program.program).replace(/\\/g, ""),
          lang: i18n.language.substring(0, 2),
        }).unwrap();
      } catch (err) {}
    }
  };
  useEffect(() => {
    if (sendProgramIsError || sendProgramIsSuccess) {
      dispatchSnackBar(
        sendProgramIsSuccess,
        sendProgramIsError,
        t("generated_program.send_success"),
        t("snackbar_messages.error_send_program")
      );
    }
  }, [sendProgramIsError, sendProgramIsSuccess]);
  return (
    <div>
      {isLoading && <Loader />}
      <StyledTitle>{t("generated_program.suggested_program")}</StyledTitle>
      {isSuccess &&
        program.program &&
        Object.keys(program.program).map((day) => (
          <div key={day}>
            <Divider orientation="horizontal" variant="middle">
              <DayTitle>{day}</DayTitle>
            </Divider>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <div>
                    <SectionTitle> {t("generated_program.meals")}</SectionTitle>
                    <StyledUl>
                      {program.program[day].meals.map(
                        (meal: any, index: number) => (
                          <li key={index}>
                            {meal.name} : {meal.description}
                          </li>
                        )
                      )}
                    </StyledUl>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div>
                    <SectionTitle>
                      {t("generated_program.activities")}
                    </SectionTitle>
                    <StyledUl>
                      {program.program[day].activities.map(
                        (activity: any, index: number) => (
                          <li key={index}>
                            {activity.name} : {activity.description}
                          </li>
                        )
                      )}
                    </StyledUl>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        ))}
      <Divider
        orientation="horizontal"
        variant="middle"
        sx={{ padding: "0.5rem 0rem 0.5rem 0rem" }}
      />
      {isSuccess && program.program && (
        <SectionTitle>
          {t("generated_program.enter_email_to_send")}
          <DownloadSection>
            <StyledTextField
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="false"
              error={emailError.length > 0}
              helperText={emailError}
            />
            <LoadingButton
              size="small"
              endIcon={<SendIcon />}
              loading={sendProgramIsLoading}
              loadingPosition="end"
              variant="contained"
              onClick={handleSend}
              disabled={sendProgramIsSuccess}
              sx={{
                width: "fit-content",
                backgroundColor: `${COLORS.GREEN.DARK_GREEN_OPACITY_90}`,
                fontSize: "0.7rem",
                height: "2rem",

                "&:hover": {
                  backgroundColor: `${COLORS.GREEN.OLIVE_GREEN}`,
                },
              }}
            >
              {t("generated_program.send")}
            </LoadingButton>
          </DownloadSection>
        </SectionTitle>
      )}
    </div>
  );
};
