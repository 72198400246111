import { styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  height: "fit-content",
});

export const StyledCalendarContainer = styled("div")({
  width: "fit-content",
  height: "100%",
});

export const StyledFormContainer = styled("div")({
  width: "fit-content",
  height: "100%",
  paddingLeft: "1rem",
});
export const StyledCalendar = styled("div")({
  justifyContent: "center",
  marginBottom: "0",
  paddingBottom: "0",
  border: "",
  backgroundColor: `${COLORS.LIGHT_SILVER}`,
  borderRadius: "12px",
  position: "relative",
  right: "0.3rem",
});
