import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, DOCTOR_BASE_URL } from "../../core/constants/paths";

export const byDoctorTaskApi = createApi({
  reducerPath: "byDoctorTaskApi",
  baseQuery: fetchBaseQuery({
    baseUrl: DOCTOR_BASE_URL,
  }),
  tagTypes: ["tasks"],
  endpoints: (builder) => ({
    tasks: builder.query({
      query: (parameters: {
        token: string;
        date: string;
        lang?: string;
        patient_id?: number;
      }) => ({
        url: `${ENDPOINTS.FETCH_PATIENTS}/${parameters.patient_id}/${ENDPOINTS.TASK}`,
        method: "GET",
        params: {
          date: parameters.date,
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      providesTags: ["tasks"],
    }),

    createTask: builder.mutation({
      query: (parameters: {
        token: string;
        date: string;
        tasks: string;
        patient_id: number;
        lang?: string;
      }) => ({
        url: `${ENDPOINTS.FETCH_PATIENTS}/${parameters.patient_id}/${ENDPOINTS.TASK}`,
        method: "POST",
        body: {
          date: parameters.date,
          tasks: parameters.tasks,
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      invalidatesTags: ["tasks"],
    }),
  }),
});
export const { useTasksQuery, useCreateTaskMutation } = byDoctorTaskApi;
