import react from "react";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getTokenFromLocalStorage,
  getUserFromLocalStorage,
  getRoleFromLocalStorage,
  removeUserFromLocalStorage,
  removeTokenFromLocalStorage,
  removeRoleFromLocalStorage,
  saveUserToLocalStorage,
  saveTokenToLocalStorage,
  saveRoleToLocalStorage,
} from "../../core/utils/storage";
import { Patient } from "../../core/models/patient.interface";
import { Doctor } from "../../core/models/doctor.interface";
const initialLoggedIn: boolean =
  getUserFromLocalStorage() &&
  getTokenFromLocalStorage() &&
  getRoleFromLocalStorage()
    ? true
    : false;
interface IUserState {
  user: Patient | Doctor | null;
  token: string | null;
  role: number | null;
  isLoggedIn: boolean;
}
const initialState: IUserState = {
  user: getUserFromLocalStorage() || null,
  token: getTokenFromLocalStorage()?.access_token || null,
  role: getRoleFromLocalStorage() || null,
  isLoggedIn: initialLoggedIn,
};
const userslice = createSlice({
  name: "userauth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { user, role, jwt, expires_in } = action.payload;
      state.user = user;
      state.role = role;
      state.token = jwt;
      state.isLoggedIn = true;
      saveUserToLocalStorage(user);
      saveTokenToLocalStorage({ access_token: jwt, expires_in: expires_in });
      saveRoleToLocalStorage(role);
    },
    logOut: (state, action) => {
      state.user = null;
      state.role = null;
      state.token = null;
      state.isLoggedIn = false;
      removeUserFromLocalStorage();
      removeTokenFromLocalStorage();
      removeRoleFromLocalStorage();
    },
  },
});
export const { setUser, logOut } = userslice.actions;
export const userauthReducer = userslice.reducer;
export const selectCurrentUser = (state: RootState) => state.userauth.user;
export const selectCurrentToken = (state: RootState) => state.userauth.token;
export const selectCurrentRole = (state: RootState) => state.userauth.role;
export const selectIsLoggedIn = (state: RootState) => state.userauth.isLoggedIn;
