import { Switch, alpha, styled } from "@mui/material";
import { pink } from "@mui/material/colors";

export const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  padding: "1rem",
  width: "fits-content",
  height: "fits-content",
});

export const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

export const StyledRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  textAlign: "center",
});
