import { List, ListSubheader, styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledLi = styled("li")({
  fontSize: "15px",
  marginTop: "0.5rem",
});

export const StyledList = styled(List)({
  width: "16rem",
  maxWidth: 360,
  bgcolor: "background.paper",
  position: "relative",
  overflow: "auto",
  maxHeight: 300,
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: `${COLORS.REDDISH_BROWN}`,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: `${COLORS.GRAY.GRAY_LIGHT}`,
  },
});
export const StyledListSubheader = styled(ListSubheader)({
  color: `${COLORS.GRAY.DARK_GRAY}`,
  marginLeft: "3rem",
  fontSize: "14px",
  width: "80%",
});
export const StyledListheader = styled(ListSubheader)({
  color: `${COLORS.REDDISH_BROWN}`,
  fontSize: "15px",
  zIndex: "10",
  paddingLeft: "3rem",
});
