import { useTranslation } from "react-i18next";
import { MaterialUISwitch } from "./LangSelect.style";
import { useState } from "react";
export const LangSelect = () => {
  const { i18n } = useTranslation();
  const [langCode, setLangCode] = useState(i18n.language.substring(0, 2));
  const onChangeLang = (e: any) => {
    i18n.changeLanguage(e.target.checked ? "fr" : "en");
    setLangCode(i18n.language.substring(0, 2));
  };

  return (
    <MaterialUISwitch
      value={langCode == "fr" ? "on" : "off"}
      checked={langCode == "fr"}
      onChange={(e) => {
        onChangeLang(e);
      }}
    />
  );
};
