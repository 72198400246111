import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, PATIENT_BASE_URL } from "../../core/constants/paths";

export const byPatientTaskApi = createApi({
  reducerPath: "byPatientTaskApi",
  baseQuery: fetchBaseQuery({
    baseUrl: PATIENT_BASE_URL,
  }),
  tagTypes: ["tasks"],
  endpoints: (builder) => ({
    tasks: builder.query({
      query: (parameters: { token: string; date: string; lang?: string }) => ({
        url: `/${ENDPOINTS.TASK}`,
        method: "GET",
        params: {
          date: parameters.date,
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      providesTags: ["tasks"],
    }),

    toggleComplete: builder.mutation({
      query: (parameters: {
        token: string;
        task_id: number;
        lang?: string;
      }) => ({
        url: `${ENDPOINTS.TASK}/${parameters.task_id}`,
        method: "PUT",
        body: {
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      invalidatesTags: ["tasks"],
    }),

    Home: builder.query({
      query: (parameters: { token: string; lang?: string }) => ({
        url: `${ENDPOINTS.HOME}`,
        method: "GET",
        params: {
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      providesTags: ["tasks"],
    }),
  }),
});
export const { useTasksQuery, useToggleCompleteMutation, useHomeQuery } =
  byPatientTaskApi;
