import { useTranslation } from "react-i18next";

export const StartConsultationConstants = () => {
  const { t } = useTranslation();
  const CONSULTATION = [
    { title: t("follow_up.height"), name: "height" },
    { title: t("follow_up.weight"), name: "weight" },
    { title: t("follow_up.heart_pressure"), name: "heart_pressure" },
    { title: t("follow_up.allergy"), name: "allergy" },
    { title: t("follow_up.current_medicament"), name: "current_medicament" },
    { title: t("follow_up.body_fat"), name: "body_fat" },
    { title: t("follow_up.cholesterol_level"), name: "cholesterol_level" },
    { title: t("follow_up.notes"), name: "notes" },
    { title: t("follow_up.target_weight"), name: "target_weight" },
  ];
  return {
    CONSULTATION,
  };
};
