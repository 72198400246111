import { CssBaseline } from "@mui/material";
import {
  StyledBox,
  StyledContainer,
  StyledImage,
  StyledText,
} from "./BoxComponent.style";
import { Iprops } from "./BoxComponent.type";
import logo from "../../images/logo.svg";
export const BoxComponent = (props: Iprops) => {
  return (
    <StyledContainer>
      <CssBaseline />
      <StyledBox width={props.width}>
        <StyledImage>
          <img src={logo} alt="Mon logo" />
        </StyledImage>
        <StyledText>{props.title}</StyledText>
        {props.children}
      </StyledBox>
    </StyledContainer>
  );
};
