import * as React from "react";
import Divider from "@mui/material/Divider";
import appointment from "../../images/appointment.svg";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import { StyledMenu, StyledMenuItem } from "./SelectMenu.style";
import EventNoteIcon from "@mui/icons-material/EventNote";
import StartIcon from "@mui/icons-material/Start";
import { IMenu } from "./SelectMenu.type";
import { ScheduleFollowUp } from "../ScheduleFollowUp/ScheduleFollowUp";
import { StartConsultation } from "../StartConsultation/StartConsultation";
import { useImmediateFollowUpMutation } from "../../redux/api/FollowUpApi";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { useState } from "react";
export const SelectMenu = (props: IMenu) => {
  const token = useSelector(selectCurrentToken) as string;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [immediateFollowUp, { isError, isLoading, isSuccess }] =
    useImmediateFollowUpMutation();
  //Schedule follow up
  const [schedule, setSchedule] = useState(false);
  const closeSchedule = () => {
    setSchedule(false);
  };
  const [followUpId, setFollowUpId] = useState(-1);
  // start immediate folllow up
  const [immmediate, setImmediate] = React.useState(false);
  const closeImmediate = () => {
    setImmediate(false);
  };

  const { t } = useTranslation();
  const handleCreateImmediate = (e: any) => {
    e.preventDefault();
    immediateFollowUp({
      patient_id: props.patientId,
      token: token,
    })
      .unwrap()
      .then((result) => {
        setFollowUpId(result.follow_up.id);
        setImmediate(true);
      })
      .catch((err) => console.error(err));
  };
  return (
    <>
      <Tooltip title={t("create_appointment")}>
        <IconButton onClick={handleClick}>
          <img src={appointment} alt="appointment" />
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={() => setSchedule(true)} disableRipple>
          <EventNoteIcon />
          {t("follow_up_schedule")}
        </StyledMenuItem>
        <Divider sx={{ my: 0.5 }} />
        <StyledMenuItem onClick={(e) => handleCreateImmediate(e)} disableRipple>
          <StartIcon />
          {t("start_consultation")}
        </StyledMenuItem>
      </StyledMenu>
      <ScheduleFollowUp
        open={schedule}
        onClose={closeSchedule}
        patientId={props.patientId}
      />
      <StartConsultation
        open={immmediate}
        title={t("start_consultation")}
        onClose={closeImmediate}
        patientId={props.patientId}
        followUpId={followUpId}
      />
    </>
  );
};
