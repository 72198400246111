import React, { useEffect, useState } from "react";
import { IFollowUpUpdate } from "./UpdateFollowup.type";
import { Button, Container, DialogActions } from "@mui/material";
import { FormDialog } from "../FormDialog/FormDialog";
import { useTranslation } from "react-i18next";
import { TextFieldComponent } from "../TextFieldComponent/TextFieldComponent";
import { dispatchSnackBar } from "../../core/utils/helpers/dispatchSnackBar";
import { useFollowupUpdateMutation } from "../../redux/api/FollowUpApi";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";

export const UpdateFollowup = (props: IFollowUpUpdate) => {
  const token = useSelector(selectCurrentToken) as string;
  const { t, i18n } = useTranslation();
  const initialState = {
    date: "",
    start: "",
    end: "",
  };
  const [values, setValues] = useState({
    date: props.date ? props.date : "",
    start: props.start ? props.start : "",
    end: props.end ? props.end : "",
  });
  useEffect(() => {
    setValues({
      date: props.date ? props.date : "",
      start: props.start ? props.start : "",
      end: props.end ? props.end : "",
    });
  }, [props]);
  const [validation, setValidation] = useState({
    start: "",
    end: "",
    error: "",
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setValidation({ ...validation, start: "", end: "" });
  };
  const [followupUpdate, { isSuccess, isError }] = useFollowupUpdateMutation();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = followupUpdate({
      token: token,
      followup_id: props.followUpId,
      date: values.date,
      start_time: values.start,
      end_time: values.end,
      lang: i18n.language.substring(0, 2),
    })
      .unwrap()
      .then((result) => {
        props.onClose();
        setValues(initialState);
      })

      .catch((err: any) => {
        setValidation({
          ...validation,
          error: err?.data?.date ? err?.data?.date : err?.data?.message,
        });
      });
  };
  useEffect(() => {
    if (isSuccess || isError) {
      dispatchSnackBar(
        isSuccess,
        isError,
        t("snackbar_messages.success_update_followup"),
        validation.error
          ? validation.error
          : t("snackbar_messages.error_update_followup")
      );
    }
  }, [isSuccess, isError, validation]);
  return (
    <>
      <FormDialog
        open={props.open}
        title={t("update_follow_up")}
        onClose={() => {
          props.onClose();
          setValues(initialState);
        }}
      >
        <form onSubmit={handleSubmit}>
          <Container>
            <TextFieldComponent
              onChange={onChange}
              name="date"
              value={values.date}
              title={t("date")}
              width="15rem"
              type="date"
              disabled={props.disableDate}
            />
            <TextFieldComponent
              onChange={onChange}
              name="start"
              value={values.start}
              title={t("followup_start_time")}
              width="15rem"
              type="time"
              validation={validation.start}
            />
            <TextFieldComponent
              onChange={onChange}
              name="end"
              value={values.end}
              title={t("followup_end_time")}
              width="15rem"
              type="time"
              validation={validation.end}
            />
          </Container>
          <DialogActions sx={{ zIndex: "10" }}>
            <Button
              color="inherit"
              onClick={() => {
                setValues(initialState);
                props.onClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button color="success" type="submit">
              {t("save")}
            </Button>
          </DialogActions>
        </form>
      </FormDialog>
    </>
  );
};
