import { Button, MenuItem, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../core/constants/colors";

export const StyledMenuItem = styled(MenuItem)({
  textDecoration: "none",
});

export const StyledConnexionLink = styled(NavLink)({
  textDecoration: "none",
  color: `${COLORS.GRAY.DARK_GRAY}`,
});

export const StyledButtonIcon = styled(Button)({
  color: `${COLORS.GREEN.DARK_GREEN_OPACITY_75}`,
});
