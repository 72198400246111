import React from "react";
import { Container, ErrorMessage, ErrorTitle } from "./Error.style";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { useTranslation } from "react-i18next";

export const Error = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <SentimentVeryDissatisfiedIcon fontSize="large" />
      <ErrorTitle>Oops...</ErrorTitle>
      <ErrorMessage>{t("page_not_found")}</ErrorMessage>
    </Container>
  );
};
