import { Box, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppLayout } from "../../layouts/AppLayout/AppLayout";
import { BoxComponent } from "../../components/BoxComponent/BoxComponent";
import { ButtonComponent } from "../../components/ButtonComponent/ButtonComponent";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VpnKeySharpIcon from "@mui/icons-material/VpnKeySharp";
import {
  useLoginMutation,
  useSendCodeMutation,
} from "../../redux/api/PatientAuthApi";
import { Loader } from "../../components/loader/Loader";
import { StyledTextField } from "./PatientAuth.style";

import { MessageComponent } from "../../components/MessageComponent/MessageComponent";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentRole, setUser } from "../../redux/slices/UserAuth";
import { useNavigate } from "react-router-dom";
import { phoneValidation } from "../../core/utils/helpers/phoneValidation";
import { getCustomError } from "../../core/utils/helpers/getCustomError";
import { PATHS } from "../../core/constants/paths";
export const PatientAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector(selectCurrentRole);
  const { t, i18n } = useTranslation();
  const [sendCode, { isLoading, isError, isSuccess }] = useSendCodeMutation();
  const [
    login,
    {
      isLoading: isloginLoading,
      isError: loginIsError,
      isSuccess: loginIsSuccess,
      error,
    },
  ] = useLoginMutation();
  const [values, setValues] = useState({
    phone_number: "",
    login_code: "",
  });
  const [validation, setValidation] = useState({
    phone_number: "",
    login_code: "",
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setValidation({ ...validation, [e.target.name]: "" });
  };
  const handleSubmitSendLoginCode = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setValidation((prevState) => ({
      ...prevState,
      phone_number:
        values.phone_number.length == 0
          ? t("error.required")
          : !phoneValidation(values.phone_number)
          ? t("error.phone_validation")
          : "",
    }));
    if (
      values.phone_number.length != 0 &&
      phoneValidation(values.phone_number)
    ) {
      try {
        const result = await sendCode({
          phone_number: values.phone_number,
          lang: i18n.language.substring(0, 2),
        }).unwrap();
      } catch (err) {
        setValidation({
          phone_number: getCustomError(err).data.phone_number
            ? (getCustomError(err).data.phone_number?.[0] as string)
            : "",
          login_code: "",
        });
      }
    }
  };
  const handleSubmitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidation((prevState) => ({
      ...prevState,
      login_code:
        values.login_code.length == 0
          ? t("error.required")
          : values.login_code.length != 6
          ? t("error.login_code")
          : "",
    }));
    if (values.login_code.length == 6) {
      try {
        const result = await login({
          phone_number: values.phone_number,
          password: values.login_code,
          lang: i18n.language.substring(0, 2),
        }).unwrap();
        const { patient, access_token, token_type, expires_in } = result;
        dispatch(
          setUser({
            user: patient,
            role: 2,
            jwt: access_token,
            expires_in: expires_in,
          })
        );
        navigate(PATHS.HOME);
      } catch (err) {
        setValidation({
          login_code: getCustomError(err).data.message
            ? (getCustomError(err).data.message as string)
            : "",
          phone_number: "",
        });
      }
    }
  };

  return (
    <AppLayout isAuth={true}>
      <BoxComponent title={t("auth.signin") as string}>
        {(isLoading || isloginLoading) && <Loader />}
        <Box
          sx={{
            width: "100%",
          }}
          component="form"
          onSubmit={isSuccess ? handleSubmitLogin : handleSubmitSendLoginCode}
        >
          <StyledTextField
            placeholder={t("user.phone") as string}
            value={values.phone_number}
            onChange={onChange}
            margin="normal"
            fullWidth
            id="phone"
            name="phone_number"
            error={validation.phone_number != ""}
            helperText={validation.phone_number}
            autoFocus
            disabled={isSuccess ? true : false}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneIcon />
                </InputAdornment>
              ),
            }}
          />
          {isSuccess && (
            <div>
              <MessageComponent
                message={t("auth.login_code_send")}
              ></MessageComponent>
              <StyledTextField
                placeholder={t("user.login_code") as string}
                value={values.login_code}
                onChange={onChange}
                margin="normal"
                fullWidth
                id="login_code"
                name="login_code"
                autoFocus
                error={validation.login_code != ""}
                helperText={validation.login_code}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeySharpIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          <ButtonComponent title={t("auth.signin") as string} />
        </Box>
      </BoxComponent>
    </AppLayout>
  );
};
