import react, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  StyledCalendar,
  StyledCalendarContainer,
  StyledContainer,
  StyledFormContainer,
} from "./ScheduleFollowUp.style";
import { ISchedule } from "./ScheduleFollowUp.type";
import { TextFieldComponent } from "../TextFieldComponent/TextFieldComponent";
import { DatePickerInput } from "../DatePickerInput/DatePickerInput";
import dayjs, { Dayjs } from "dayjs";
import { FormDialog } from "../FormDialog/FormDialog";
import { SelectedDayFollowUps } from "../SelectedDayFollowUps/SelectedDayFollowUps";
import { useAddFollowUpMutation } from "../../redux/api/FollowUpApi";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { dispatchSnackBar } from "../../core/utils/helpers/dispatchSnackBar";

export const ScheduleFollowUp = (props: ISchedule) => {
  const initialState = {
    date: dayjs().format("YYYY-MM-DD"),
    start_time: "",
    end_time: "",
  };
  const initialValidation = {
    date: "",
    start_time: "",
    end_time: "",
    error: "",
  };
  const [values, setValues] = useState(initialState);
  const [validation, setValidation] = useState(initialValidation);
  const handleChangeDate = (newDate: Dayjs | null) => {
    setValues({ ...values, date: newDate ? newDate.format("YYYY-MM-DD") : "" });
  };
  const { t, i18n } = useTranslation();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setValidation(initialValidation);
  };
  const token = useSelector(selectCurrentToken) as string;
  const [create, { isError, isSuccess }] = useAddFollowUpMutation();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidation({
      ...validation,
      date: values.date.length > 0 ? "" : t("error.required"),
      start_time: values.start_time.length > 0 ? "" : t("error.required"),
      end_time: values.end_time.length > 0 ? "" : t("error.required"),
    });
    if (
      values.date.length > 0 &&
      values.start_time.length > 0 &&
      values.end_time.length > 0
    ) {
      try {
        const result = await create({
          token: token,
          start_time: values.start_time,
          end_time: values.end_time,
          date: values.date,
          patient_id: props.patientId,
          lang: i18n.language.substring(0, 2),
        }).unwrap();
        setValues({ ...values, start_time: "", end_time: "" });
      } catch (err: any) {
        setValidation({
          ...validation,
          error: err?.data?.message ? err?.data?.message : "",
        });
      }
    }
  };
  //create user snackbar
  useEffect(() => {
    if (isSuccess || isError) {
      dispatchSnackBar(
        isSuccess,
        isError,
        t("snackbar_messages.success_schedule_FollowUp"),
        validation.error != ""
          ? validation.error
          : t("snackbar_messages.error_schedule_FollowUp")
      );
    }
  }, [isSuccess, isError, validation.error]);
  return (
    <>
      <FormDialog
        open={props.open}
        title={t("follow_up_schedule")}
        onClose={() => {
          props.onClose();
          setValues(initialState);
        }}
      >
        <StyledContainer>
          <StyledCalendarContainer>
            <SelectedDayFollowUps day={values.date} />
          </StyledCalendarContainer>
          <StyledFormContainer>
            <form onSubmit={handleSubmit}>
              <StyledCalendar>
                <DatePickerInput
                  value={values.date}
                  handleChangeDate={handleChangeDate}
                />
              </StyledCalendar>
              <TextFieldComponent
                onChange={onChange}
                name="start_time"
                value={values.start_time}
                title={t("followup_start_time")}
                width="15rem"
                type="time"
                validation={validation.start_time}
              />
              <TextFieldComponent
                onChange={onChange}
                name="end_time"
                value={values.end_time}
                title={t("followup_end_time")}
                width="15rem"
                type="time"
                validation={validation.end_time}
              />
              <DialogActions>
                <Button color="inherit" onClick={props.onClose}>
                  {t("cancel")}
                </Button>
                <Button color="success" type="submit">
                  {t("save")}
                </Button>
              </DialogActions>
            </form>
          </StyledFormContainer>
        </StyledContainer>
      </FormDialog>
    </>
  );
};
