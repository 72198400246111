import { DialogTitle, styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";
export const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: `${COLORS.GREEN.FOREST_GREEN}`,
  color: "white",
  zIndex: "100",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
