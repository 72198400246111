import { styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledDiv = styled("div")({
  width: "95%",
  padding: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  marginRight: "0!important",
});
export const StyledContainer = styled("div")({
  width: "100%",
  display: "flex",
  padding: "0rem 1rem 1rem 1rem",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  marginRight: "0!important",
  borderRadius: "1.5rem",
  backgroundColor: `${COLORS.GREEN.LIGHTEST_GREEN}`,
  "& div ": {
    "& .fc .fc-button": {
      backgroundColor: `${COLORS.GREEN.DUSTY_GREEN}`,
      border: `${COLORS.GREEN.DUSTY_GREEN}`,
    },
    "& .fc .fc-button-primary:not(:disabled).fc-button-active": {
      backgroundColor: `${COLORS.GREEN.DARK_GREEN}`,
      border: `${COLORS.RED}`,
      boxShadow: "none",
    },
    "& fc-button-primary": {
      "& press": {
        backgroundColor: `${COLORS.GRAY.DARK_GRAY}`,
      },
    },
    "& fc-timeGridWeek-button fc-button fc-button-primary fc-button-active": {
      color: `${COLORS.GRAY.DARK_GRAY}`,
    },
  },
});

export const Title = styled("div")({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  color: `${COLORS.DARK_PURPLE}`,
  fontSize: "1.5em",
  fontWeight: "bold",
  padding: "1.5rem",
});

export const CentredTable = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
});
