import { useTranslation } from "react-i18next";
import { PATHS } from "../../core/constants/paths";
import HomeIcon from "@mui/icons-material/Home";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SellIcon from "@mui/icons-material/Sell";
export const SideBarConstants = () => {
  const { t } = useTranslation();
  const PATIENT_MENU_ITEMS = [
    {
      path: PATHS.HOME,
      name: t("menu.home"),
      icon: <HomeIcon />,
    },
    {
      path: PATHS.PROFIL,
      name: t("menu.profil"),
      icon: <MedicalInformationIcon />,
    },
    {
      path: PATHS.CHAT,
      name: t("menu.chat"),
      icon: <ChatBubbleIcon />,
    },
  ];

  const DOCTOR_MENU_ITEMS = [
    {
      path: PATHS.HOME,
      name: t("menu.home"),
      icon: <HomeIcon />,
    },
    {
      path: PATHS.MY_PATIENTS,
      name: t("doctor_menu.my_patients"),
      icon: <SupervisorAccountIcon />,
    },
    {
      path: PATHS.MY_CALENDAR,
      name: t("menu.calendar"),
      icon: <CalendarMonthIcon />,
    },
  ];
  const DOCTOR_MENU = [
    {
      path: PATHS.CONVERSATIONS,
      name: t("doctor_menu.conversations"),
      icon: <QuestionAnswerIcon />,
    },
    {
      path: PATHS.SUBSCRIPTION,
      name: t("doctor_menu.subscription"),
      icon: <SellIcon />,
    },
  ];
  return [PATIENT_MENU_ITEMS, DOCTOR_MENU_ITEMS, DOCTOR_MENU];
};
