import { Grid, Paper, Stack, styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

interface IItem {
  width?: string;
}
export const Item = styled(Paper)<IItem>(({ theme, width }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "row",
  textAlign: "start",
  paddingBottom: "0.5rem",
  borderRadius: "20px",
  overflow: "hidden",
  width: width ? `${width}` : "5rem",
  fontSize: "0.9rem",
  fontWeight: "bold",
  backgroundColor: `${COLORS.GREEN.LIGHTEST_GREEN}`,
  color: `${COLORS.BLACK_OPACITY_54}`,
  margin: "0.5rem",
  paddingLeft: "1rem",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: `${COLORS.GOLDEN_YELLOW}`,
  },
}));

export const Title = styled(Grid)({
  fontWeight: "bold",
  fontSize: "1rem",
  color: `${COLORS.GREEN.DARK_OLIVE_GREEN}`,
  paddingBottom: "2rem",
});

export const SubTitle = styled(Grid)({
  fontWeight: "bold",
  fontSize: "0.85rem",
  color: `${COLORS.DARK_PURPLE}`,
  textAlign: "start",
  padding: "0.5rem 0rem 1rem 3rem",
  textDecoration: "underline",
  fontStyle: "italic",
});
export const StyledText = styled(Grid)({
  fontSize: "0.8rem",
  textAlign: "center",
  width: "100%",
});

export const StyledStack = styled(Stack)({
  display: "flex",
  justifyContent: "justify",
});
