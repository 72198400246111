import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DOCTOR_BASE_URL } from "../../core/constants/paths";

export const doctorAuthApi = createApi({
  reducerPath: "doctorAuthApi",
  baseQuery: fetchBaseQuery({
    baseUrl: DOCTOR_BASE_URL,
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body: { email: string; password: string; lang?: string }) => ({
        url: "login",
        method: "POST",
        body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    register: builder.mutation({
      query: (body: {
        first_name: string;
        last_name: string;
        email: string;
        address: string;
        phone_number: string;
        password: string;
        password_confirmation: string;
        lang?: string;
      }) => ({
        url: "register",
        method: "POST",
        body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body: { email: string; lang?: string }) => ({
        url: "forgot-password",
        method: "POST",
        body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: (body: {
        token: string;
        email: string;
        password: string;
        password_confirmation: string;
        lang?: string;
      }) => ({
        url: "reset-password",
        method: "POST",
        body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    logout: builder.mutation({
      query: (token: string) => ({
        url: "logout",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }),
    }),
  }),
});
export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLogoutMutation,
} = doctorAuthApi;
