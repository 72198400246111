import { styled, Button, Paper } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledTitle = styled("div")({
  color: `${COLORS.GREEN.DUSTY_GREEN}`,
  fontSize: "20px",
  paddingLeft: "2rem",
});
export const StyledButton = styled(Button)({
  width: "100%",
  backgroundColor: `${COLORS.GREEN.DUSTY_GREEN}`,
  color: "white",
  border: `${COLORS.GREEN.DUSTY_GREEN}`,
  "&:hover": {
    backgroundColor: `${COLORS.GREEN.DARK_GREEN}`,
  },
});

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  "&:hover": {
    cursor: "pointer",
  },
  "&.active": {
    backgroundColor: `${COLORS.GOLDEN_YELLOW}`,
  },
}));
