import { styled, Dialog, DialogTitle, DialogContentText } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledDialogTitle = styled(DialogTitle)({
  color: `${COLORS.GREEN.DARK_GREEN_OPACITY_75}`,
  marginBottom: "1rem",
  textAlign: "center",
  fontSize: "1.5rem",
  justifyContent: "center",
});

export const StyledTitle = styled("p")({
  color: `${COLORS.DARK_ORANGE}`,
  fontSize: "1.2rem",
});

export const StyledDialog = styled(Dialog)({
  backgroundColor: `${COLORS.GRAY.GRAY_LIGHT}`,
  padding: "20px",
});

export const StyledDialogContentText = styled(DialogContentText)({
  textAlign: "justify",
});
