import React, { useEffect, useState } from "react";
import { PinkSwitch, StyledDiv, StyledRow } from "./PersonalInformations.style";

import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";
import { TextFieldComponent } from "../TextFieldComponent/TextFieldComponent";
import { useTranslation } from "react-i18next";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import {
  useProfileQuery,
  useUpdateMutation,
} from "../../redux/api/PatientAuthApi";
import { IPersonalInfo } from "./PersonalInformations.type";
import dayjs from "dayjs";
import { ListItemText, Stack, Typography } from "@mui/material";
import male from "../../images/male.svg";
import female from "../../images/female.svg";
import { emailValidation } from "../../core/utils/helpers/emailValidation";
import { phoneMask } from "../../core/utils/helpers/phoneMask";
import { dispatchSnackBar } from "../../core/utils/helpers/dispatchSnackBar";
import { COLORS } from "../../core/constants/colors";
import { GlobalVlariables } from "../../core/constants/constant";

const label = { inputProps: { "aria-label": "Color switch demo" } };
export const PersonalInformations = (props: IPersonalInfo) => {
  const token = useSelector(selectCurrentToken) as string;
  const { t, i18n } = useTranslation();
  const { data } = useProfileQuery({
    token: token,
    lang: i18n.language.substring(0, 2),
  });
  const [user, setUser] = useState({
    name: "",
    gender: null,
    birth_date: dayjs().format("YYYY-MM-DD"),
    is_pregnant: false,
    phone_number: "",
    email: "",
    since: "",
    doctor_name: "",
    next_follow_up: "",
  });
  useEffect(() => {
    setUser({
      ...user,
      name: data?.patient?.name,
      gender: data?.patient?.gender,
      birth_date: dayjs(data?.patient?.birth_date, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
      is_pregnant: data?.patient?.pregnant,
      phone_number: data?.patient?.phone_number,
      email: data?.patient?.email ? data?.patient?.email : "",
      since: data?.patient?.since,
      doctor_name: data?.patient?.doctor,
      next_follow_up: data?.patient?.next_follow_up,
    });
    props.setPatientName(data?.patient?.name);
    props.setPatientGender(data?.patient?.gender);
  }, [data]);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    setValidation({
      birth_date: "",
      phone_number: "",
      email: "",
    });
  };
  const [validation, setValidation] = useState({
    birth_date: "",
    phone_number: "",
    email: "",
  });
  const [patientUpdate, { isSuccess, isError }] = useUpdateMutation();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      user.birth_date.length > 0 &&
      user.phone_number.length > 0 &&
      phoneMask(user.phone_number) !== "" &&
      (user.email.length === 0 || emailValidation(user.email))
    ) {
      const result = patientUpdate({
        token: token,
        birth_date: user.birth_date,
        phone_number: phoneMask(user.phone_number),
        email: user.email,
        is_pregnant: user.is_pregnant ? 1 : 0,
        lang: i18n.language.substring(0, 2),
      })
        .unwrap()
        .then()
        .catch((err: any) => {});
    } else {
      setValidation({
        ...validation,
        birth_date: user.birth_date.length === 0 ? t("error.required") : "",
        phone_number:
          user.phone_number.length === 0
            ? t("error.required")
            : phoneMask(user.phone_number) == ""
            ? t("error.phone_mask")
            : "",
        email:
          user.email.length > 0 && !emailValidation(user.email)
            ? t("error.email_validation")
            : "",
      });
    }
  };
  //create user snackbar
  useEffect(() => {
    if (isSuccess || isError) {
      dispatchSnackBar(
        isSuccess,
        isError,
        t("snackbar_messages.success_update_profile"),
        t("snackbar_messages.error_update_profile")
      );
    }
  }, [isSuccess, isError]);
  return (
    <StyledDiv>
      <form onSubmit={handleSubmit}>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <StyledRow>
                <ListItemText
                  primary={t("doctor")}
                  primaryTypographyProps={{
                    style: { color: "green", fontSize: "17px" },
                  }}
                  secondary={user.doctor_name}
                  secondaryTypographyProps={{
                    style: { color: "black" },
                  }}
                />
                <ListItemText
                  primary={t("user.patient_since")}
                  primaryTypographyProps={{
                    style: { color: "green", fontSize: "17px" },
                  }}
                  secondary={user.since}
                  secondaryTypographyProps={{
                    style: { color: "black" },
                  }}
                />
                <ListItemText
                  primary={t("next_follow_up")}
                  primaryTypographyProps={{
                    style: { color: "green", fontSize: "17px" },
                  }}
                  secondary={user.next_follow_up}
                  secondaryTypographyProps={{
                    style: { color: "black" },
                  }}
                />
              </StyledRow>
              <br />

              <Grid item xs={12} sm={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>{t("user.gender")}</Typography>
                  <img
                    src={
                      user.gender == GlobalVlariables.GENDER.FEMALE
                        ? female
                        : user.gender == GlobalVlariables.GENDER.MALE
                        ? male
                        : ""
                    }
                    alt={
                      user.gender == GlobalVlariables.GENDER.FEMALE
                        ? (t("gender.female") as string)
                        : (t("gender.male") as string)
                    }
                  />
                </Stack>
              </Grid>
              <StyledRow>
                {user.gender == GlobalVlariables.GENDER.FEMALE && (
                  <Grid item xs={12} sm={6}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>{t("pregnant")}</Typography>
                      <PinkSwitch
                        {...label}
                        value={user.is_pregnant}
                        checked={user.is_pregnant}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            is_pregnant: !user.is_pregnant,
                          });
                        }}
                      />
                    </Stack>
                  </Grid>
                )}
              </StyledRow>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldComponent
                onChange={onChange}
                title={t("user.birth_date")}
                value={user.birth_date}
                name="birth_date"
                width="90%"
                backgroundColor={COLORS.WHITE}
                type="date"
                validation={validation.birth_date}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldComponent
                onChange={onChange}
                title={t("user.phone")}
                value={user.phone_number}
                name="phone_number"
                width="90%"
                backgroundColor={COLORS.WHITE}
                validation={validation.phone_number}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldComponent
                onChange={onChange}
                title={t("user.email")}
                value={user.email}
                name="email"
                width="90%"
                backgroundColor={COLORS.WHITE}
              />
            </Grid>
          </Grid>
        </Box>
        <ButtonComponent title="Update" />
      </form>
    </StyledDiv>
  );
};
