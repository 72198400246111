import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  StyledDialogContentText,
  StyledDialogTitle,
  StyledTitle,
} from "./NotApprovedComponent.style";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { INotApproved } from "./NotApproved.type";
export const NotApprovedComponent = (props: INotApproved) => {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    navigate("/signin");
  };
  const { t } = useTranslation();
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <StyledDialogTitle id="responsive-dialog-title">
          {props.title}
        </StyledDialogTitle>
        <DialogContent>
          <StyledDialogContentText>
            <StyledTitle>{t("not_approved.title")}</StyledTitle>
            {t("not_approved.p1")}
            <br />
            {t("not_approved.p2")}
            <br />
            {t("not_approved.p3")}
            <br />
          </StyledDialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonComponent onClick={handleClose} title=" OK"></ButtonComponent>
        </DialogActions>
      </Dialog>
    </div>
  );
};
