import { styled } from "@mui/material";

export const CentredTable = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center", // center the content horizontally
  alignItems: "center", // center the content vertically
  textAlign: "center",
});
export const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
});
