import React, { useEffect, useState } from "react";
import {
  StyledLi,
  StyledList,
  StyledListSubheader,
  StyledListheader,
} from "./SelectedDayFollowUps.style";
import { IDateFollowUps } from "./SelectedDayFollowUps.type";
import {
  useDeleteFollowupMutation,
  useFollowupsQuery,
} from "../../redux/api/FollowUpApi";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { DateFollowUp } from "../../shared/interfaces/FollowUp";
import { morningOrAfterNoonFollowUp } from "../../core/utils/helpers/morningOrAfterNoonFollowUp";
import { dispatchSnackBar } from "../../core/utils/helpers/dispatchSnackBar";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import { useTranslation } from "react-i18next";
export const SelectedDayFollowUps = (props: IDateFollowUps) => {
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken) as string;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const handleCloseConfirm = () => {
    setShowConfirmDialog(false);
  };
  const { data: followups, isLoading } = useFollowupsQuery({
    token: token,
    date: props.day ? props.day : new Date().toLocaleDateString(),
    // date: props.day
    //   ? { type: "day", day: props.day }
    //   : { type: "day", day: new Date().toLocaleDateString() },
  });
  const [
    deleteFollowup,
    { isError: deleteFollowupError, isSuccess: deleteFollowupSuccess },
  ] = useDeleteFollowupMutation();
  const [morningFollowUps, afternoonFollowUps] = morningOrAfterNoonFollowUp(
    followups?.follow_ups
  );
  //create user snackbar
  useEffect(() => {
    if (deleteFollowupError || deleteFollowupSuccess) {
      dispatchSnackBar(
        deleteFollowupSuccess,
        deleteFollowupError,
        t("snackbar_messages.success_delete_FollowUp"),
        t("snackbar_messages.error_delete_FollowUp")
      );
    }
  }, [deleteFollowupError, deleteFollowupSuccess]);
  return (
    <StyledList
      subheader={<StyledListheader>{t("appointments")}</StyledListheader>}
    >
      {isLoading && (
        <CircularProgress
          color="inherit"
          size={15}
          sx={{ textAligh: "center" }}
        />
      )}
      {followups?.follow_ups.length > 0 && (
        <>
          <StyledListSubheader>{t("morning")}</StyledListSubheader>
          {morningFollowUps?.length > 0
            ? morningFollowUps?.map((morning: DateFollowUp) => (
                <StyledLi>
                  <Tooltip title="Delete">
                    <IconButton
                      color="error"
                      onClick={() => {
                        deleteFollowup({
                          followup_id: morning.id,
                          token: token,
                        });
                      }}
                    >
                      <HighlightOffIcon sx={{ fontSize: "1rem!important" }} />
                    </IconButton>
                  </Tooltip>
                  {morning.start_time} - {morning.end_time}:{" "}
                  {morning.first_name} {morning.last_name}
                </StyledLi>
              ))
            : "_"}
          <StyledListSubheader>{t("afternoon")}</StyledListSubheader>
          {afternoonFollowUps?.length > 0
            ? afternoonFollowUps?.map((afternoon: DateFollowUp) => (
                <StyledLi>
                  <Tooltip title="Delete">
                    <IconButton
                      color="error"
                      onClick={() => setShowConfirmDialog(true)}
                    >
                      <HighlightOffIcon sx={{ fontSize: "1rem!important" }} />
                    </IconButton>
                  </Tooltip>
                  {afternoon.start_time} - {afternoon.end_time}:{" "}
                  {afternoon.first_name} {afternoon.last_name}
                  <ConfirmDialog
                    handleClose={handleCloseConfirm}
                    open={showConfirmDialog}
                    title={t("delete_followup") as string}
                    text={t("sure_to_delete_followup") as string}
                    onAgree={() =>
                      deleteFollowup({
                        followup_id: afternoon.id,
                        token: token,
                      })
                    }
                  ></ConfirmDialog>
                </StyledLi>
              ))
            : "_"}
        </>
      )}
    </StyledList>
  );
};
