import react, { useState } from "react";
import { StyledDiv, StyledMenuItem } from "./ProfilMenu.style";
import { Stack } from "@mui/system";
import { Divider, ListItemText, MenuList, Typography } from "@mui/material";
import man from "../../images/man.svg";
import woman from "../../images/woman.svg";
import { useTranslation } from "react-i18next";
import { IProfilMenu } from "./ProfilMenu.type";
import { COLORS } from "../../core/constants/colors";
import { GlobalVlariables } from "../../core/constants/constant";
export const ProfilMenu = (props: IProfilMenu) => {
  const { t } = useTranslation();
  const handleItemClick = (itemId: number) => {
    props.onItemClick(itemId);
  };
  return (
    <StyledDiv>
      <Stack spacing={3} direction="row" alignItems="center" marginBottom={2}>
        <Stack>
          <img
            src={
              props.patientGender === GlobalVlariables.GENDER.FEMALE
                ? woman
                : props.patientGender === GlobalVlariables.GENDER.MALE
                ? man
                : ""
            }
            alt="user"
          />
        </Stack>
        <Stack sx={{ minWidth: 0 }}>
          <Typography
            noWrap
            variant="h6"
            sx={{ color: `${COLORS.LIGHT_PURPLE}`, fontFamily: "Arial" }}
          >
            {props.patientName}
          </Typography>
        </Stack>
      </Stack>
      <MenuList>
        <StyledMenuItem
          onClick={() => handleItemClick(1)}
          className={props.activeItem === 1 ? "active" : ""}
        >
          <ListItemText>{t("personal_informations")}</ListItemText>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem
          onClick={() => handleItemClick(2)}
          className={props.activeItem === 2 ? "active" : ""}
        >
          <ListItemText>{t("medical_history")}</ListItemText>
        </StyledMenuItem>
        <Divider />

        <StyledMenuItem
          onClick={() => handleItemClick(3)}
          className={props.activeItem === 3 ? "active" : ""}
        >
          <ListItemText>{t("tracking_chart")}</ListItemText>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem
          onClick={() => handleItemClick(4)}
          className={props.activeItem === 4 ? "active" : ""}
        >
          <ListItemText>{t("tasks")}</ListItemText>
        </StyledMenuItem>
      </MenuList>
    </StyledDiv>
  );
};
