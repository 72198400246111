import { useByDoctorTrackingQuery } from "../../redux/api/FollowUpApi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import { trackingChartConfig } from "../../core/utils/helpers/trackingChartConfig";

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);
export const MyPatientTracking = (props: { patientId: number }) => {
  const token = useSelector(selectCurrentToken) as string;
  const { data } = useByDoctorTrackingQuery({
    token: token,
    patient_id: props.patientId,
  });
  let dates = [] as string[];
  let weights = [] as number[];
  const INIT_CHART_DATA = trackingChartConfig();
  const [chartData, setChartData] = useState(INIT_CHART_DATA);
  useEffect(() => {
    if (data) {
      dates = [];
      weights = [];
      setChartData(INIT_CHART_DATA);
      data.follow_ups.forEach((followUp: any) => {
        dates.push(followUp.date);
        weights.push(followUp.weight);
      });
      setChartData((prevChartData) => ({
        ...prevChartData,
        labels: dates,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data: weights,
          },
        ],
      }));
    }
  }, [data]);
  return (
    <div style={{ width: "100%" }}>
      <Line data={chartData} />
    </div>
  );
};
