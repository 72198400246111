import { useByDoctorDetailsQuery } from "../../redux/api/FollowUpApi";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { useSelector } from "react-redux";
import { IDetails } from "./ByDoctorFollowUpDetails.type";
import { Grid } from "@mui/material";
import { StartConsultationConstants } from "../StartConsultation/StartConsultation.constants";
import {
  CriteriaTitle,
  CriteriaValue,
  StyledTitle,
} from "../MyPatientHistory/MyPatientHistory.style";
import { useTranslation } from "react-i18next";
export const ByDoctorFollowUpDetails = (props: IDetails) => {
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken) as string;
  const { data } = useByDoctorDetailsQuery({
    token: token,
    followup_id: props.followUpId,
  });
  const { CONSULTATION: CRITERIA } = StartConsultationConstants();
  return (
    <>
      <StyledTitle>{t("follow_up.examination")}</StyledTitle>
      {CRITERIA?.map((attribute) => (
        <Grid container sx={{ marginLeft: "2rem", maxWidth: "90%" }}>
          <Grid item md={4} xs={4}>
            <CriteriaTitle>{attribute.title}:</CriteriaTitle>
          </Grid>
          <Grid item md={8} xs={8}>
            <CriteriaValue>{data?.details?.[attribute.name]}</CriteriaValue>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
