import React, { useState } from "react";
import { ColumnDiv, StyledDiv, StyledTextField } from "./Register.style";

import { Box, IconButton, InputAdornment, FormControl } from "@mui/material";
import DraftsSharpIcon from "@mui/icons-material/DraftsSharp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import GiteIcon from "@mui/icons-material/Gite";
import PersonIcon from "@mui/icons-material/Person";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import VpnKeySharpIcon from "@mui/icons-material/VpnKeySharp";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ButtonComponent } from "../../components/ButtonComponent/ButtonComponent";

import { useTranslation } from "react-i18next";
import { useRegisterMutation } from "../../redux/api/DoctorAuthApi";
import { AppLayout } from "../../layouts/AppLayout/AppLayout";
import { Loader } from "../../components/loader/Loader";
import { NotApprovedComponent } from "../../components/notApprovedComponent/NotApprovedComponent";
import { emailValidation } from "../../core/utils/helpers/emailValidation";
import { phoneValidation } from "../../core/utils/helpers/phoneValidation";
import { confirmPasswordValidation } from "../../core/utils/helpers/confirmPasswordValidation";
import { getCustomError } from "../../core/utils/helpers/getCustomError";
import { BoxComponent } from "../../components/BoxComponent/BoxComponent";
export const Register = () => {
  const initError = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    password: "",
    confirmPassword: "",
    emailConfirmation: "",
    phoneConfirmation: "",
    passwordDoesNotMatch: "",
  };
  const initServerSideError = {
    first_name: "",
    last_name: "",
    email: "",
    emailError: "",
    address: "",
    phone_number: "",
    password: "",
    passwordError: "",
    error: "",
  };
  const [register, { isLoading }] = useRegisterMutation();
  const { t, i18n } = useTranslation();
  const [serverSideError, setServerSideError] = useState(initServerSideError);
  const [showPopup, setShowPopup] = useState(false);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
  });
  const [error, setError] = useState(initError);
  const handleChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setError(initError);
    setServerSideError(initServerSideError);
  };
  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const settingError = await setError((prevState) => ({
      ...prevState,
      firstName: state.firstName.length == 0 ? t("error.required") : "",
      lastName: state.lastName.length == 0 ? t("error.required") : "",
      email: state.email.length == 0 ? t("error.required") : "",
      phoneNumber: state.phoneNumber.length == 0 ? t("error.required") : "",
      address: state.address.length == 0 ? t("error.required") : "",
      password: state.password.length == 0 ? t("error.required") : "",
      confirmPassword:
        state.confirmPassword.length == 0 ? t("error.required") : "",
    }));
    if (!emailValidation(state.email)) {
      setError((prevState) => ({
        ...prevState,
        emailConfirmation: t("error.email_validation"),
      }));
    }
    if (!phoneValidation(state.phoneNumber)) {
      setError((prevState) => ({
        ...prevState,
        phoneConfirmation: t("error.phone_validation"),
      }));
    }
    if (!confirmPasswordValidation(state.password, state.confirmPassword)) {
      setError((prevState) => ({
        ...prevState,
        passwordDoesNotMatch: t("error.password_match"),
      }));
    }
    console.log("error before if", error);
    if (
      state.firstName.length > 0 &&
      state.lastName.length > 0 &&
      state.email.length > 0 &&
      state.phoneNumber.length > 0 &&
      state.address.length > 0 &&
      state.password.length > 0 &&
      state.confirmPassword.length > 0 &&
      emailValidation(state.email) &&
      confirmPasswordValidation(state.password, state.confirmPassword) &&
      phoneValidation(state.phoneNumber)
    ) {
      try {
        const response = await register({
          first_name: state.firstName,
          last_name: state.lastName,
          email: state.email,
          address: state.address,
          phone_number: state.phoneNumber,
          password: state.password,
          password_confirmation: state.confirmPassword,
          lang: i18n.language.substring(0, 2),
        }).unwrap();
        setShowPopup(true);
      } catch (err) {
        setServerSideError({
          first_name: getCustomError(err).data.first_name?.[0] as string,
          last_name: getCustomError(err).data.last_name?.[0] as string,
          email: getCustomError(err).data.email
            ? (getCustomError(err).data.email?.[0] as string)
            : "",
          emailError: getCustomError(err).data.emailError as string,
          address: getCustomError(err).data.address?.[0] as string,
          phone_number: getCustomError(err).data.phone_number
            ? (getCustomError(err).data.phone_number?.[0] as string)
            : "",
          password: getCustomError(err).data.password?.[0] as string,
          passwordError: getCustomError(err).data.passwordError as string,
          error: getCustomError(err).data.error as string,
        });
      }
    }
  };
  return (
    <AppLayout isAuth={true}>
      <BoxComponent title={t("auth.signup") as string} width="45rem">
        {isLoading && <Loader />}
        <Box component="form" onSubmit={handleSubmit}>
          <FormControl fullWidth={true}>
            <span>{serverSideError.error}</span>
            <ColumnDiv>
              <StyledDiv>
                <ColumnDiv>
                  <StyledTextField
                    placeholder={t("user.first_name") as string}
                    value={state.firstName}
                    onChange={handleChange}
                    margin="dense"
                    id="firstName"
                    name="firstName"
                    autoComplete="firstName"
                    autoFocus
                    error={error.firstName != ""}
                    helperText={error.firstName}
                    sx={{ paddingRight: "1rem" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PermIdentityIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <StyledTextField
                    placeholder={t("user.last_name") as string}
                    value={state.lastName}
                    onChange={handleChange}
                    margin="dense"
                    id="lastName"
                    name="lastName"
                    autoComplete="lastName"
                    error={error.lastName != ""}
                    helperText={error.lastName}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </ColumnDiv>
                <StyledTextField
                  placeholder="Email"
                  value={state.email}
                  onChange={handleChange}
                  margin="dense"
                  id="email"
                  name="email"
                  autoComplete="email"
                  error={
                    serverSideError.email != "" ||
                    error.email != "" ||
                    error.emailConfirmation != ""
                  }
                  helperText={
                    error.email != ""
                      ? error.email
                      : error.emailConfirmation != ""
                      ? error.emailConfirmation
                      : serverSideError.email
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DraftsSharpIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledTextField
                  margin="dense"
                  placeholder={t("user.phone") as string}
                  value={state.phoneNumber}
                  onChange={handleChange}
                  id="phoneNumber"
                  name="phoneNumber"
                  autoComplete="phoneNumber"
                  error={
                    serverSideError.phone_number != "" ||
                    error.phoneNumber != "" ||
                    error.phoneConfirmation != ""
                  }
                  helperText={
                    error.phoneNumber != ""
                      ? error.phoneNumber
                      : error.phoneConfirmation != ""
                      ? error.phoneConfirmation
                      : serverSideError.phone_number
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledDiv>
              <StyledDiv>
                <StyledTextField
                  placeholder={t("user.address") as string}
                  value={state.address}
                  onChange={handleChange}
                  margin="dense"
                  id="address"
                  name="address"
                  autoComplete="address"
                  error={error.address != ""}
                  helperText={error.address}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GiteIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledTextField
                  placeholder={t("user.password") as string}
                  value={state.password}
                  onChange={handleChange}
                  margin="dense"
                  name="password"
                  type={state.showPassword ? "text" : "password"}
                  id="password"
                  error={error.password != ""}
                  helperText={error.password}
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeySharpIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {!state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledTextField
                  placeholder={t("auth.confirm_password") as string}
                  value={state.confirmPassword}
                  type="password"
                  onChange={handleChange}
                  margin="dense"
                  id="confirmPassword"
                  name="confirmPassword"
                  autoComplete="confirmPassword"
                  error={
                    error.confirmPassword != "" ||
                    error.passwordDoesNotMatch != ""
                  }
                  helperText={`${error.passwordDoesNotMatch} \n ${error.confirmPassword}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeySharpIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledDiv>
            </ColumnDiv>
          </FormControl>
          <ButtonComponent title={t("auth.submit") as string} width="100%" />
          {showPopup && (
            <NotApprovedComponent
              title={t("not_approved.created") || undefined}
            />
          )}
        </Box>
      </BoxComponent>
    </AppLayout>
  );
};
