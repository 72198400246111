import React from "react";
import doctor from "../../images/doctor.svg";
import patient from "../../images/patient.svg";
import openai from "../../images/openai.svg";
import { useTranslation } from "react-i18next";
import { PATHS } from "../../core/constants/paths";
export const HomePageConstants = () => {
  const { t } = useTranslation();
  const ELEMENTS = [
    {
      image: doctor,
      description: t("home_page.doctor_functionnalities"),
      link: PATHS.SIGNIN,
      buttonTitle: t("home_page.doctor_login"),
    },
    {
      image: patient,
      description: t("home_page.patient_functionnalities"),
      link: PATHS.PATIENT_AUTH,
      buttonTitle: t("home_page.patient_login"),
    },
    {
      image: openai,
      description: t("home_page.generated_program"),
      link: PATHS.GENERATE_PROGRAM,
      buttonTitle: t("home_page.try_program"),
    },
  ];
  return ELEMENTS;
};
