import { Box, Grid, Paper, styled } from "@mui/material";

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  //margin: "0rem 0.5rem 0rem 1rem",
  paddingBottom: "0.5rem",
  borderRadius: "20px",
  boxShadow: "none",
  border: "1px solid #a9b9a6d6",
  overflow: "hidden",
}));

export const MainBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});
export const DescriptionBox = styled(Box)({
  flexGrow: 1,
  textAlign: "start",
  padding: "0rem 0.5rem 0rem 1rem",
  fontSize: "0.8rem",
  fontWeight: "bold",
});
export const ButtonBox = styled(Box)({
  textAlign: "end",
  width: "100%",
  paddingRight: "1rem",
});

export const ContainerGrid = styled(Grid)({
  padding: "0rem 1.5rem 0.5rem 1.5rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
});
