import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Login } from "./pages/Login/Login";
import { Register } from "./pages/Register/Register";
import { ForgetPassword } from "./pages/ForgetPassword/ForgetPassword";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { PATHS } from "./core/constants/paths";
import { Error } from "./pages/Error/Error";
import { HomePage } from "./pages/HomePage/HomePage";
import { createTheme, ThemeProvider } from "@mui/material";
import { PatientAuth } from "./pages/PatientAuth/PatientAuth";
import PatientGuard from "./shared/guards/PatientGuard";
import DoctorGuard from "./shared/guards/DoctorGuard";
import GuestGuard from "./shared/guards/GuestGuard";
import { Patients } from "./pages/Patients/Patients";
import { Calendar } from "./pages/Calendar/Calendar";
import { PatientProfil } from "./pages/PatientProfil/PatientProfil";
import { OpenAiForm } from "./components/OpenAiForm/OpenAiForm";
const theme = createTheme();
function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path={PATHS.HOME} element={<HomePage />}></Route>
              <Route
                path={PATHS.GENERATE_PROGRAM}
                element={<OpenAiForm />}
              ></Route>
              <Route
                path={PATHS.RESET_PASSWORD}
                element={
                  <GuestGuard>
                    <ResetPassword />
                  </GuestGuard>
                }
              ></Route>
              <Route
                path={PATHS.SIGNIN}
                element={
                  <GuestGuard>
                    <Login />
                  </GuestGuard>
                }
              ></Route>
              <Route
                path={PATHS.SIGNUP}
                element={
                  <GuestGuard>
                    <Register />
                  </GuestGuard>
                }
              ></Route>
              <Route
                path={PATHS.FORGOT_PASSWORD}
                element={
                  <GuestGuard>
                    <ForgetPassword />
                  </GuestGuard>
                }
              ></Route>
              <Route
                path={PATHS.PATIENT_AUTH}
                element={
                  <GuestGuard>
                    <PatientAuth />
                  </GuestGuard>
                }
              />
              <Route path="*" element={<Error />} />
              {/* Doctor Routes */}
              <Route
                path={PATHS.MY_PATIENTS}
                element={
                  <DoctorGuard>
                    <Patients />
                  </DoctorGuard>
                }
              />
              <Route
                path={PATHS.CONVERSATIONS}
                element={
                  <DoctorGuard>
                    <HomePage />
                  </DoctorGuard>
                }
              />
              <Route
                path={PATHS.MY_CALENDAR}
                element={
                  <DoctorGuard>
                    <Calendar />
                  </DoctorGuard>
                }
              />
              <Route
                path={PATHS.SUBSCRIPTION}
                element={
                  <DoctorGuard>
                    <HomePage />
                  </DoctorGuard>
                }
              />
              <Route
                path={PATHS.MY_PATIENT_PROFILE}
                element={
                  <DoctorGuard>
                    <PatientProfil />
                  </DoctorGuard>
                }
              />
              {/* Patient Routes */}
              <Route
                path={PATHS.CALENDAR}
                element={
                  <PatientGuard>
                    <HomePage />
                  </PatientGuard>
                }
              />
              <Route
                path={PATHS.NOTIFICATIONS}
                element={
                  <PatientGuard>
                    <HomePage />
                  </PatientGuard>
                }
              />
              <Route
                path={PATHS.PROFIL}
                element={
                  <PatientGuard>
                    <PatientProfil />
                  </PatientGuard>
                }
              />
              <Route
                path={PATHS.CHAT}
                element={
                  <PatientGuard>
                    <HomePage />
                  </PatientGuard>
                }
              />
            </Routes>
          </BrowserRouter>
        </div>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
