import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";
import { PATHS } from "../../core/constants/paths";

interface Props {
  children: ReactNode;
}

function GuestGuard(props: Props) {
  const { children } = props;
  const isLoggedIn = useLoggedIn();

  if (isLoggedIn) {
    return <Navigate to={PATHS.HOME} replace />;
  }
  return <>{children}</>;
}
export default GuestGuard;
