import { styled, Box, Container } from "@mui/material";
import { Iprops } from "./BoxComponent.type";
import { COLORS } from "../../core/constants/colors";

export const StyledContainer = styled(Container)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  backgroundPosition: "center center",
  maxWidth: "100%!important",
});
export const StyledBox = styled(Box)<Iprops>(({ width, theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  width: width ? `${width}` : "500px !important",
  padding: "0.5rem 3rem 3rem",
  borderRadius: "30px",
  border: `1px solid ${COLORS.GREEN.GREENISH_GRAY}`,
  marginTop: "0.5rem",
  marginBottom: "5rem important",
  backgroundColor: "white",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },

  [theme.breakpoints.down("xs")]: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
}));

export const StyledText = styled("h2")({
  fontSize: "1.2rem",
  marginBottom: "2rem",
  color: `${COLORS.GREEN.DARK_GREEN_OPACITY_75}`,
  textAlign: "center",
  fontFamily: "georgia",
});

export const StyledImage = styled("div")({
  fontSize: "3rem",
  textAlign: "center",
  padding: "0rem",
});
