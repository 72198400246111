export const COLORS = {
  GREEN: {
    DUSTY_GREEN: "#749F82",
    OLIVE_GREEN: "#7FB77E",
    BOTTLE_GREEN: "#00b098",
    DARK_GREEN: "#425F57",
    LIGHT_GREEN: "#D8E4DD",
    LIGHTEST_GREEN: "#e0e7e3e6",
    FOREST_GREEN: "#52745F",
    DARK_OLIVE_GREEN: "#2e7d32",
    GREENISH_GRAY: "#a9b9a6d6",
    DARK_GREEN_OPACITY_75: "#14532dbf",
    DARK_GREEN_OPACITY_90: "#14532de6",
  },
  GRAY: {
    GRAY_DARK: "#202020d1",
    GRAY_DARKER: "#202020",
    GRAY_MEDIUM: "#E8F0FE",
    GRAY_LIGHT: "#f2f2f2",
    BASIC_GRAY: "#eeeeee",
    GRAY_LIGHTER: "#f5f5f5",
    LIGHT_GRAY: "#f3f3f3",
    CHARCOAL_GRAY: "#333",
    DARK_GRAY: "grey",
    BLUE_GRAY: "#1A2027",
    GRAY_OPACITY_09: "rgba(238, 238, 238, 0.9)",
    GRAY_OPACITY_12: "#0000001F",
    GRAY_OPACITY_14: "#00000024",
    GRAY_OPACITY_20: "#00000033",
    GRAY_OPACITY_50: "#00000080",
    GRAY_OPACITY_70: "#000000B3",
    GRAY_OPACITY_32: "#00000051",
  },

  REDDISH_BROWN: "#CB9879",
  BURGUNDY: "#d32f2f",
  BLACK_OPACITY_54: "#0000008a",
  LIGHT_BLUE: "#8787872b",
  DARK_PURPLE: "#713F12",
  LIGHT_PURPLE: "#45304c",
  DARK_ORANGE: "#A16207",
  BROWN: "brown",
  GOLDEN_YELLOW: "#F2CD5C",
  LIGHT_SILVER: "#F4F4F4",
  RED: "#f44336",
  WHITE: "#fff",
  WHITE_OPACITY_95: "#F9F9F9",
  LIGHT_BLUE_OPACITY: "#f5fff37a",
  YELLOW: "#ffeb3b",
  DARK_RED: "#8B0000",
  WHISPER: "#EEEEE6",
};
