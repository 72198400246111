import { Button, styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
  textAlign: "end",
  marginTop: "1rem",
});
export const StyledTextArea = styled("textarea")({
  marginTop: "2rem",
  minHeight: "8rem",
  border: "none",
  width: "100%",
  "&:focus": {
    outline: "none",
  },
});

export const StyledButton = styled(Button)({
  fontWeight: "bold",
  borderRadius: "50px !important",
  color: `${COLORS.GRAY.DARK_GRAY}`,
  backgroundColor: `${COLORS.LIGHT_BLUE}`,
  height: "fit-content",
  width: "fit-content",
  border: "none !important",
  cursor: "pointer",
  textTransform: "none",
  padding: "0.5rem",
  "&:hover": {
    backgroundColor: `${COLORS.GRAY.DARK_GRAY}`,
  },
});
