import { DateFollowUp } from "../../../shared/interfaces/FollowUp";
export const morningOrAfterNoonFollowUp = (followups: Array<DateFollowUp>) => {
  const morningFollowUps: DateFollowUp[] = [];
  const afternoonFollowUps: DateFollowUp[] = [];

  followups?.forEach((followup: DateFollowUp) => {
    const startTimeHours = parseInt(followup.start_time.split(":")[0]);
    if (startTimeHours < 12) {
      morningFollowUps.push(followup);
    } else {
      afternoonFollowUps.push(followup);
    }
  });

  return [morningFollowUps, afternoonFollowUps];
};
