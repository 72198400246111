import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
export interface ErrorType {
  data: {
    error?: string;
    first_name?: string[];
    last_name?: string[];
    email?: string[];
    address?: string[];
    password?: string[];
    phone_number?: string[];
    emailError?: string;
    passwordError?: string;
    message?: string;
  };
  status?: string;
  end_time?: string;
}

/**
 * Check if the error is a network error
 */
export function isConnectionError(
  error: unknown
): error is FetchBaseQueryError {
  return (
    typeof error === "object" &&
    error != null &&
    "status" in error &&
    (error as { status: string | number }).status === "FETCH_ERROR"
  );
}

/**
 * Takes an error and extract the error message if exists
 */
export function getCustomError(error: unknown): ErrorType {
  return {
    data: {
      error: (error as ErrorType).data?.error,
      email: (error as ErrorType).data?.email,
      password: (error as ErrorType).data?.password,
      emailError: (error as ErrorType).data?.emailError,
      passwordError: (error as ErrorType).data?.passwordError,
      first_name: (error as ErrorType).data?.first_name,
      last_name: (error as ErrorType).data?.last_name,
      address: (error as ErrorType).data?.address,
      phone_number: (error as ErrorType).data?.phone_number,
      message: (error as ErrorType).data?.message,
    },
    status: (error as ErrorType).status as string,
    end_time: (error as ErrorType).end_time as string,
  };
}
