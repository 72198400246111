import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, PATIENT_BASE_URL } from "../../core/constants/paths";

export const patientAuthApi = createApi({
  reducerPath: "patientAuthApi",
  baseQuery: fetchBaseQuery({
    baseUrl: PATIENT_BASE_URL,
  }),
  tagTypes: ["Patient"],
  endpoints: (builder) => ({
    sendCode: builder.mutation({
      query: (body: { phone_number: string; lang?: string }) => ({
        url: "send-code",
        method: "POST",
        body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    login: builder.mutation({
      query: (body: {
        phone_number: string;
        password: string;
        lang?: string;
      }) => ({
        url: "login",
        method: "POST",
        body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    patientLogout: builder.mutation({
      query: (token: string) => ({
        url: "logout",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }),
    }),
    profile: builder.query({
      query: (params: { token: string; lang?: string }) => ({
        url: `/${ENDPOINTS.PROFILE}/${ENDPOINTS.MAIN}`,
        method: "GET",
        params: { lang: params.lang },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      providesTags: ["Patient"],
    }),
    update: builder.mutation({
      query: (params: {
        token: string;
        birth_date: string;
        phone_number: string;
        email?: string;
        is_pregnant: number;
        lang?: string;
      }) => ({
        url: `/${ENDPOINTS.PROFILE}`,
        method: "PUT",
        body: {
          ...(params.email && { email: params.email }),
          birth_date: params.birth_date,
          phone_number: params.phone_number,
          is_pregnant: params.is_pregnant,
          lang: params.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      invalidatesTags: ["Patient"],
    }),
  }),
});
export const {
  useProfileQuery,
  useUpdateMutation,
  usePatientLogoutMutation,
  useSendCodeMutation,
  useLoginMutation,
} = patientAuthApi;
