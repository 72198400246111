import { phoneValidation } from "./phoneValidation";

export const phoneMask = (phone: string) => {
  var country_code = phone.slice(0, 4);
  if (country_code !== "+216") {
    var splited = phone.split(" ").join("");
    if (phoneValidation(splited)) {
      return splited;
    }
    return "";
  }
  var number = phone.slice(4, phone.length + 1);
  var splited = number.split(" ").join("");
  if (phoneValidation(splited)) {
    return splited;
  }
  return "";
};
