import { NavLink } from "react-router-dom";
import { styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledNav = styled("nav")(({ theme }) => ({
  boxSizing: "border-box",
  color: "color",
  display: "flex",
  justifyContent: "end",
  paddingLeft: "20px",
  paddingRight: "20px",
  fontFamily: "system-ui",
  fontWeight: "bold",
  height: "5rem",
  width: "100%",
}));

export const StyledList = styled("ul")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  padding: "0",
  margin: "0",
  listStyle: "none",
  gap: "1 rem",
  position: "relative",
  right: "2rem",
  height: "100%",

  [theme.breakpoints.down(768)]: {
    flexDirection: "column",
    gap: "0",
  },
}));
export const StyledLi = styled("li")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "1rem",
  marginRight: "1rem",

  [theme.breakpoints.down(768)]: {
    margin: "0",
    marginLeft: "2rem",
  },
}));
export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  height: "100%!important",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: `${COLORS.GREEN.DARK_GREEN_OPACITY_75}`,
  margin: "0",

  "&.active": {
    borderBottom: `${COLORS.GREEN.DARK_GREEN_OPACITY_75}`,
  },

  [theme.breakpoints.down(768)]: {
    width: "100%",
    justifyContent: "center",
    padding: "0.5rem",
  },
}));

export const LinksContainer = styled("div")({
  width: "60%",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  margin: "0",
  marginLeft: "5rem",
  height: "100%",
});
export const TitleContainer = styled("div")({
  width: "40%",
  display: "flex",
  justifyContent: "start",
});

export const ResponsiveStyledList = styled(StyledList)(({ theme }) => ({
  [theme.breakpoints.down(767)]: {
    display: "none",
  },
}));
