import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useDeletePatientMutation,
  usePatientsQuery,
} from "../../redux/api/PatientsApi";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { Patient } from "../../core/models/patient.interface";
import {
  Search,
  StyledBodyCell,
  StyledContainer,
  StyledHeadCell,
  StyledTableContainer,
  StyledTableRow,
  TableAction,
  Title,
} from "./TableComponent.style";

import DeleteIcon from "@mui/icons-material/Delete";
import TableSortLabel from "@mui/material/TableSortLabel";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  Paper,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import female from "../../images/female.svg";
import male from "../../images/male.svg";
import pregnant from "../../images/pregnant.svg";
import { TableComponentConstants } from "./TableComponent.constants";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import { SelectMenu } from "../SelectMenu/SelectMenu";
import { dispatchSnackBar } from "../../core/utils/helpers/dispatchSnackBar";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../core/constants/paths";
import { ITable } from "./TableComponent.type";
import { COLORS } from "../../core/constants/colors";
import { GlobalVlariables } from "../../core/constants/constant";

export const TableComponent = (props: ITable) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { HEAD_ARRAY, DEFAUL_ORDER_CULUMN, DEFAULT_ORDER_TYPE } =
    TableComponentConstants();
  const [pagination, setPagination] = useState({
    search: "",
    page: "1",
    per_page: "5",
    order_column: DEFAUL_ORDER_CULUMN,
    order_type: DEFAULT_ORDER_TYPE,
  });
  const headArray = HEAD_ARRAY;
  const token = useSelector(selectCurrentToken) as string;
  const [
    deletePatient,
    { isError: deletePatientError, isSuccess: deletePatientSuccess },
  ] = useDeletePatientMutation();
  const { data: patients, isLoading } = usePatientsQuery({
    token: token,
    page: pagination.page,
    per_page: pagination.per_page,
    search: pagination.search,
    order_column: pagination.order_column,
    order_type: pagination.order_type,
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [patientId, setPatientId] = useState(-1);
  const handleClose = () => {
    setShowConfirmDialog(false);
  };
  //create user snackbar
  React.useEffect(() => {
    if (deletePatientSuccess || deletePatientError) {
      dispatchSnackBar(
        deletePatientSuccess,
        deletePatientError,
        t("snackbar_messages.success_delete_patient"),
        t("snackbar_messages.error_delete_patient")
      );
    }
  }, [deletePatientSuccess, deletePatientError]);
  const rowClick = (patientId: number) => {
    navigate(PATHS.MY_PATIENT_PROFILE.replace(":id", patientId.toString()));
  };
  return (
    <StyledContainer>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "1.5rem",
        }}
      >
        <StyledTableContainer>
          <Title>Patients</Title>
          <Divider sx={{ borderColor: `${COLORS.WHITE} ` }} />
          <TableAction>
            <Search>
              <SearchIcon />
              <InputBase
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPagination({ ...pagination, search: e.target.value });
                }}
                placeholder={t("search") + "..."}
              />
            </Search>
            {props.children}
          </TableAction>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {headArray.map((head) => (
                  <StyledHeadCell align="center">
                    {head.value}
                    {head.key != "actions" && (
                      <>
                        <TableSortLabel
                          sx={{
                            "& .MuiTableSortLabel-icon": {
                              opacity: "0.2 !important",
                            },
                            ...(head.key === pagination?.order_column &&
                              pagination.order_type === "asc" && {
                                "& .MuiTableSortLabel-icon": {
                                  opacity: "1",
                                },
                              }),
                          }}
                          active={
                            head.key == pagination?.order_column &&
                            pagination.order_type == "asc"
                          }
                          direction={"asc"}
                          onClick={() => {
                            setPagination({
                              ...pagination,
                              order_column: head.key,
                              order_type: "asc",
                            });
                          }}
                        ></TableSortLabel>
                        <TableSortLabel
                          sx={{
                            "& .MuiTableSortLabel-icon": {
                              opacity: "0.2",
                            },
                            ...(head.key === pagination?.order_column &&
                              pagination.order_type === "desc" && {
                                "& .MuiTableSortLabel-icon": {
                                  opacity: "1",
                                },
                              }),
                          }}
                          active={
                            head.key == pagination?.order_column &&
                            pagination.order_type == "desc"
                          }
                          direction={"desc"}
                          onClick={() => {
                            setPagination({
                              ...pagination,
                              order_column: head.key,
                              order_type: "desc",
                            });
                          }}
                        ></TableSortLabel>
                      </>
                    )}
                  </StyledHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {patients?.patients?.data?.length > 0
                ? patients?.patients?.data.map((row: Patient) => (
                    <StyledTableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <StyledBodyCell
                        align="center"
                        onClick={() => rowClick(row.id)}
                      >
                        {row.first_name}
                      </StyledBodyCell>
                      <StyledBodyCell
                        align="center"
                        onClick={() => rowClick(row.id)}
                      >
                        {row.last_name}
                      </StyledBodyCell>

                      <StyledBodyCell
                        align="center"
                        onClick={() => rowClick(row.id)}
                      >
                        {row.phone_number}
                      </StyledBodyCell>
                      <StyledBodyCell
                        align="center"
                        onClick={() => rowClick(row.id)}
                      >
                        {new Date(
                          parseInt(row.birth_date) * 1000
                        ).toLocaleDateString()}
                      </StyledBodyCell>
                      <StyledBodyCell
                        align="center"
                        onClick={() => rowClick(row.id)}
                      >
                        <img
                          src={
                            row.gender == GlobalVlariables.GENDER.FEMALE
                              ? row.is_pregnant == 1
                                ? pregnant
                                : female
                              : male
                          }
                          alt={
                            row.gender == GlobalVlariables.GENDER.FEMALE
                              ? row.is_pregnant == 1
                                ? "pregnant"
                                : (t("gender.female") as string)
                              : (t("gender.male") as string)
                          }
                        />
                      </StyledBodyCell>
                      <StyledBodyCell
                        align="center"
                        onClick={() => rowClick(row.id)}
                      >
                        {row.email ? row.email : "_"}
                      </StyledBodyCell>
                      <StyledBodyCell align="center" sx={{ minWidth: "84px" }}>
                        <SelectMenu patientId={row.id} />
                        <Tooltip title={t("delete_patient")}>
                          <IconButton
                            color="error"
                            onClick={() => {
                              setPatientId(row.id);
                              setShowConfirmDialog(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledBodyCell>
                    </StyledTableRow>
                  ))
                : t("no_patients")}
            </TableBody>
          </Table>
          <ConfirmDialog
            handleClose={handleClose}
            open={showConfirmDialog}
            title={t("delete_patient") as string}
            text={t("sure_to_delete_patient") as string}
            onAgree={() =>
              deletePatient({
                patient_id: patientId,
                token: token,
              })
            }
          ></ConfirmDialog>
          {isLoading && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={patients?.patients?.total ? patients?.patients?.total : 0}
            rowsPerPage={parseInt(pagination.per_page)}
            page={
              patients?.patients?.current_page - 1
                ? patients?.patients?.current_page - 1
                : 0
            }
            onPageChange={(event, newPage) => {
              setPagination({ ...pagination, page: `${newPage + 1}` });
            }}
            onRowsPerPageChange={(event) => {
              setPagination({ ...pagination, per_page: event.target.value });
            }}
          />
        </StyledTableContainer>
      </TableContainer>
    </StyledContainer>
  );
};
