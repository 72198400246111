import { styled, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../core/constants/colors";
export const NavlinkStyled = styled(NavLink)({
  textDecoration: "none",
  fontWeight: "bold",
  color: `${COLORS.WHITE} `,
  "&.active": {
    color: `${COLORS.GOLDEN_YELLOW} `,
  },
});
export const StyledTitle = styled("div")({
  display: "flex",
  flexDirection: "row",
  textAlign: "center",
  justifyContent: "center",
  margin: "0rem!important",
  width: "100%",
});

export const StyledImage = styled("div")({
  margin: "1rem",
  padding: "0.5rem 2rem 0.5rem 2rem",
  width: "fit-content",
  height: "fit-content",
  borderRadius: "20px",
  backgroundColor: `${COLORS.WHITE} `,
});
export const StyledSideBarButton = styled(Button)({
  height: "100%",
  display: "flex",
  alignItems: "center",
  color: `${COLORS.GRAY.DARK_GRAY}!important`,
  position: "relative",
  left: "2rem",
});

export const StyleDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  textAlign: "end",
  fontWeight: "bold!important",
  color: `${COLORS.WHITE} `,
});
