import * as React from "react";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { StepIconProps } from "@mui/material/StepIcon";
import { OpenAiFormConstants } from "./OpenAiForm.constant";

import {
  ColorlibConnector,
  ColorlibStepIconRoot,
  StyledCard,
  StyledDiv,
  StyledTitleDiv,
} from "./OpenAiForm.style";
import infos from "../../images/infos.svg";
import goal from "../../images/goal.svg";
import important from "../../images/important.svg";
import generate from "../../images/generate.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import GenericGuestForm from "../GenericGuestForm/GenericGuestForm";
import { CssBaseline } from "@mui/material";
import { AppLayout } from "../../layouts/AppLayout/AppLayout";

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  const { t } = useTranslation();
  const icons: { [index: string]: React.ReactElement } = {
    1: <img src={infos} alt={t("infos") as string} />,
    2: <img src={goal} alt={t("infos") as string} />,
    3: <img src={important} alt={t("infos") as string} />,
    4: <img src={generate} alt={t("infos") as string} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export const OpenAiForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const handlePrevious = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };
  const handleNext = (generated?: boolean) => {
    if (currentStep < 2) setCurrentStep(currentStep + 1);
    if (currentStep === 2 && generated) {
      setCurrentStep(currentStep + 1);
    }
  };
  const STEPS = OpenAiFormConstants();

  return (
    <AppLayout>
      <Stack
        sx={{
          width: "100%",
        }}
      >
        <Stepper
          alternativeLabel
          activeStep={currentStep}
          connector={<ColorlibConnector />}
        >
          {STEPS.map((step) => (
            <Step key={step.title}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <StyledTitleDiv
                  className={
                    step.title == STEPS[currentStep].title ? "active" : ""
                  }
                >
                  {step.title}
                </StyledTitleDiv>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <CssBaseline />
        <StyledDiv>
          <StyledCard>
            <GenericGuestForm
              content={STEPS[currentStep]}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              currentStep={currentStep}
            />
          </StyledCard>
        </StyledDiv>
      </Stack>
    </AppLayout>
  );
};
