import { COLORS } from "../../constants/colors";

export const trackingChartConfig = () => {
  const INIT_CHART_DATA = {
    labels: [""],
    datasets: [
      {
        label: "Weight",
        data: [0],
        backgroundColor: "transparent",
        borderColor: `${COLORS.GREEN.DARK_OLIVE_GREEN}`,
        tension: 0.4,
        fill: true,
        pointStyle: "cerc",
        pointBorderColor: `${COLORS.GOLDEN_YELLOW}`,
        pointBackgroundColor: `${COLORS.GOLDEN_YELLOW}`,
        showLine: true,
      },
    ],
    options: {
      responsive: true,
      scales: {
        xAxes: [
          {
            type: "time",
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Date",
            },
            ticks: {
              major: {
                fontStyle: "bold",
                fontColor: "#FF0000",
              },
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Weight",
            },
          },
        ],
      },
    },
  };
  return INIT_CHART_DATA;
};
