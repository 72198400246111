import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { IConfirm } from "./ConfirmDialog.type";

export const ConfirmDialog = (props: IConfirm) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose}>
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              props.onAgree();
              props.handleClose();
            }}
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
