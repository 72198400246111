import react from "react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DOCTOR_BASE_URL, ENDPOINTS } from "../../core/constants/paths";
import { RootState } from "../store";

export const patientApi = createApi({
  reducerPath: "patientsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: DOCTOR_BASE_URL,
  }),
  tagTypes: ["Patients"],
  endpoints: (builder) => ({
    patients: builder.query({
      query: (params: {
        token: string;
        page: string;
        per_page: string;
        search?: string;
        order_type?: string;
        order_column?: string;
      }) => ({
        url: ENDPOINTS.FETCH_PATIENTS,
        method: "GET",
        params: {
          page: params.page,
          per_page: params.per_page,
          search: params.search,
          order_type: params.order_type,
          order_column: params.order_column,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      providesTags: ["Patients"],
    }),
    addPatient: builder.mutation({
      query: (params: {
        token: string;
        first_name: string;
        last_name: string;
        phone_number: string;
        gender: string;
        birth_date: string;
        email: string;
        is_pregnant: string;
        lang?: string;
      }) => ({
        url: ENDPOINTS.CREATE_PATIENT,
        method: "POST",
        body: {
          first_name: params.first_name,
          last_name: params.last_name,
          phone_number: params.phone_number,
          gender: params.gender,
          birth_date: params.birth_date,
          email: params.email,
          is_pregnant: params.is_pregnant,
          lang: params.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      invalidatesTags: ["Patients"],
    }),
    deletePatient: builder.mutation({
      query: (params: { patient_id: number; token: string }) => ({
        url: `${ENDPOINTS.FETCH_PATIENTS}/${params.patient_id}`,
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      invalidatesTags: ["Patients"],
    }),
    getPatient: builder.query({
      query: (params: {
        patient_id: number;
        token: string;
        lang?: string;
      }) => ({
        url: `${ENDPOINTS.FETCH_PATIENTS}/${params.patient_id}/${ENDPOINTS.PROFILE}/${ENDPOINTS.MAIN}`,
        method: "GET",
        params: { lang: params.lang },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      providesTags: ["Patients"],
    }),
  }),
});
export const {
  useGetPatientQuery,
  useDeletePatientMutation,
  usePatientsQuery,
  useAddPatientMutation,
} = patientApi;
