import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHomeQuery } from "../../redux/api/ByPatientTaskApi";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import {
  Item,
  StyledStack,
  StyledText,
  SubTitle,
  Title,
} from "./PatientHomePage.style";
import checked from "../../images/checked.svg";
import notChecked from "../../images/notChecked.svg";
import { useToggleCompleteMutation } from "../../redux/api/ByPatientTaskApi";
export const PatientHomePage = () => {
  const { t, i18n } = useTranslation();
  const token = useSelector(selectCurrentToken) as string;
  const { data, isLoading } = useHomeQuery({
    token: token,
    lang: i18n.language.substring(0, 2),
  });

  const [toggleComplete] = useToggleCompleteMutation();
  const updateStatus = async (taskId: number) => {
    const result = await toggleComplete({
      token: token,
      lang: i18n.language.substring(0, 2),
      task_id: taskId,
    })
      .unwrap()
      .then()
      .catch();
  };
  return (
    <Grid container direction="column" spacing={2} sx={{ paddingTop: "2rem" }}>
      <Title>
        {t("welcome")} {data?.data?.name}
      </Title>
      {data?.data?.next_follow_up && (
        <SubTitle>
          {t("patient_home_page.next_follow_up") + data?.data?.next_follow_up}
        </SubTitle>
      )}
      {["today", "yesterday"].map((day) => (
        <>
          <SubTitle>{t(`patient_home_page.${day}_tasks`)}</SubTitle>
          <Stack
            direction="column"
            sx={{
              flexWrap: "wrap",
            }}
          >
            {isLoading ? (
              <StyledText>
                <CircularProgress color="inherit" />
              </StyledText>
            ) : (
              <>
                {data?.data?.tasks?.[day]?.map((task: any) => (
                  <StyledStack
                    direction="column"
                    spacing={{ xs: 1, md: 3 }}
                    sx={{
                      padding: "0rem 3rem 0rem 2rem",
                    }}
                    key={task.id}
                  >
                    <Item width="100%">
                      <div>{task.content}</div>
                      <IconButton
                        edge="end"
                        aria-label="comments"
                        sx={{
                          height: "20px",
                          width: "20px",
                          position: "fixed",
                          right: "15rem",
                        }}
                      >
                        <Checkbox
                          checked={task?.complete == "1"}
                          icon={
                            <img
                              src={notChecked}
                              alt={t("uncompleted") as string}
                              style={{ height: "18px" }}
                            />
                          }
                          checkedIcon={
                            <img
                              src={checked}
                              alt={t("completed") as string}
                              style={{ height: "18px" }}
                            />
                          }
                          onChange={(e) => {
                            updateStatus(task.id);
                          }}
                        />
                      </IconButton>
                    </Item>
                  </StyledStack>
                ))}
                {data?.data?.tasks?.[day]?.length === 0 && (
                  <StyledText>
                    {t(`patient_home_page.${day}_no_tasks`)}
                  </StyledText>
                )}
              </>
            )}
          </Stack>
        </>
      ))}
    </Grid>
  );
};
