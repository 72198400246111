import React, { useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import DraftsSharpIcon from "@mui/icons-material/DraftsSharp";
import { BoxComponent } from "../../components/BoxComponent/BoxComponent";
import { ButtonComponent } from "../../components/ButtonComponent/ButtonComponent";
import { useTranslation } from "react-i18next";
import { useForgotPasswordMutation } from "../../redux/api/DoctorAuthApi";
import { AppLayout } from "../../layouts/AppLayout/AppLayout";
import { Loader } from "../../components/loader/Loader";
import { MessageComponent } from "../../components/MessageComponent/MessageComponent";
import { emailValidation } from "../../core/utils/helpers/emailValidation";
import { getCustomError } from "../../core/utils/helpers/getCustomError";
import { StyledTextField } from "./ForgetPassword.style";
export const ForgetPassword = () => {
  const [forgotPassword, { isLoading, isSuccess }] =
    useForgotPasswordMutation();
  const [state, setState] = useState("");
  const [error, setError] = useState<string | null>("");
  const [status, setStatus] = useState("");
  const { t, i18n } = useTranslation();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(
      state.length == 0
        ? t("error.required")
        : !emailValidation(state)
        ? t("error.email_validation")
        : ""
    );
    if (state.length != 0 && emailValidation(state)) {
      try {
        const response = await forgotPassword({
          email: state,
          lang: i18n.language.substring(0, 2),
        }).unwrap();

        setStatus(response.status);
        setError("");
      } catch (err) {
        setError(getCustomError(err).data.email?.[0] as string);
      }
    }
  };
  return (
    <AppLayout isAuth={true}>
      <BoxComponent title={t("auth.forgot_passord") as string}>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <StyledTextField
            required
            placeholder="Email"
            value={state}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setState(e.target.value);
              setError("");
            }}
            margin="normal"
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            error={error != ""}
            helperText={error ? error : null}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DraftsSharpIcon />
                </InputAdornment>
              ),
            }}
          />
          <br />
          {isLoading && <Loader />}
          {isSuccess && <MessageComponent message={status}></MessageComponent>}
          <ButtonComponent title={t("auth.submit") as string} />
        </Box>
      </BoxComponent>
    </AppLayout>
  );
};
