import React, { ReactNode, useEffect, useState } from "react";
import { FormDialog } from "../FormDialog/FormDialog";
import { IConsultation } from "./StartConsultation.type";
import { useTranslation } from "react-i18next";
import { Button, DialogActions } from "@mui/material";
import { TextFieldComponent } from "../TextFieldComponent/TextFieldComponent";
import { StartConsultationConstants } from "./StartConsultation.constants";
import { StyledContainer } from "./StartConsultation.style";

import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { dispatchSnackBar } from "../../core/utils/helpers/dispatchSnackBar";
import { useFollowUpSubmitMutation } from "../../redux/api/FollowUpApi";
import { AddTasksModal } from "../AddTasksModal/AddTasksModal";
export const StartConsultation = (props: IConsultation) => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const initialState = {
    height: "",
    weight: "",
    target_weight: "",
    heart_pressure: "",
    allergy: "",
    current_medicament: "",
    body_fat: "",
    glucose_level: "",
    cholesterol_level: "",
    notes: "",
  };
  const [values, setValues] = useState(initialState);
  const { t } = useTranslation();
  const [submitFollowUp, { isError, isLoading, isSuccess }] =
    useFollowUpSubmitMutation();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    //setValidation(initialValidation);
  };
  const token = useSelector(selectCurrentToken) as string;
  const { CONSULTATION } = StartConsultationConstants();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const result = submitFollowUp({
        ...values,
        followup_id: props.followUpId,
        token: token,
      });
      props.onClose();
      setValues(initialState);
      setOpen(true);
    } catch (err) {}
  };
  useEffect(() => {
    if (isSuccess || isError) {
      dispatchSnackBar(
        isSuccess,
        isError,
        t("snackbar_messages.success_create_consultation"),
        t("snackbar_messages.error_create_consultation")
      );
    }
  }, [isSuccess, isError]);
  return (
    <>
      <FormDialog
        open={props.open}
        title={props.title}
        onClose={() => {
          props.onClose();
          setValues(initialState);
        }}
      >
        <form onSubmit={handleSubmit}>
          <StyledContainer>
            {CONSULTATION?.map((attribute) => (
              <TextFieldComponent
                autofocus={attribute.name === "height"}
                onChange={onChange}
                name={attribute.name}
                value={values[attribute.name as keyof typeof values]}
                title={attribute.title}
                width="22rem"
              />
            ))}
          </StyledContainer>
          <DialogActions sx={{ zIndex: "10" }}>
            <Button
              color="inherit"
              onClick={() => {
                setValues(initialState);
                props.onClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button color="success" type="submit">
              {t("save")}
            </Button>
          </DialogActions>
        </form>
      </FormDialog>
      <AddTasksModal
        patientId={props.patientId}
        open={open}
        onClose={onClose}
      />
    </>
  );
};
