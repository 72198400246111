import { useTranslation } from "react-i18next";

export const MyPatientHistoryConstants = () => {
  const { t } = useTranslation();
  const STATUS_MESSAGES = [
    t("follow_up.status.waiting"),
    t("follow_up.status.finished"),
    t("follow_up.status.canceled"),
  ];
  const STATUS_COLOR = ["warning", "success", "error"] as const;
  return [STATUS_MESSAGES, STATUS_COLOR];
};
