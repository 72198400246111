import { styled } from "@mui/system";
import { COLORS } from "../../core/constants/colors";

export const LoaderOverlay = styled("div")({
  position: "fixed",
  zIndex: 9999,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: ` ${COLORS.GRAY.GRAY_OPACITY_50}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const LoaderSpinner = styled("div")({
  border: `4px solid  ${COLORS.GRAY.LIGHT_GRAY}`,
  borderTop: `4px solid ${COLORS.GRAY.DARK_GRAY}`,
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  animation: "spin 2s linear infinite",
});
const keyframesSpin = {
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
};

export const LoaderStyles = styled("div")({
  "@keyframes spin": keyframesSpin,
  animationName: "spin",
});
