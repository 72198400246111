import { Button, ButtonTypeMap, ExtendButtonBase } from "@mui/material";
import { styled } from "@mui/material";
import { IButton } from "./ButtonComponent.type";
import { COLORS } from "../../core/constants/colors";

export const StyledButton = styled(Button)<IButton>(
  ({ width }) =>
    ({ theme }) => ({
      marginTop: "20px !important",
      marginBottom: "20px !important",
      width: width ? `${width}` : "150px !important",
      borderRadius: "50px !important",
      backgroundColor: `${COLORS.GREEN.DARK_GREEN_OPACITY_75}!important`,
      color: `${COLORS.WHITE}`,
      padding: "10px 15px !important",
      border: "none !important",
      cursor: "pointer",
    })
);
