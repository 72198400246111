import { styled } from "@mui/material";
import { IText } from "./TextFieldComponent.type";
import { COLORS } from "../../core/constants/colors";
interface IStyledTextField {
  widthProp?: string;
  backgroundColor?: string;
  noMarginTop?: boolean;
}
export const StyledTextField = styled("div")<IStyledTextField>(
  ({ widthProp }) =>
    ({ backgroundColor }) => ({
      width: widthProp ? `${widthProp}` : "30rem!important",
      backgroundColor: backgroundColor
        ? `${backgroundColor}`
        : `${COLORS.LIGHT_SILVER}`,
      borderRadius: "10px",
      justifyContent: "space-between",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      marginTop: "0.2rem",
    })
);
export const StyledTextContainer = styled("div")<IStyledTextField>(
  ({ noMarginTop }) => ({
    marginTop: noMarginTop ? "0rem" : "1rem",
  })
);
export const StyledSpan = styled("span")({
  fontSize: "12px",
});

export const StyledChildenContainer = styled("div")({});
