import React, { useEffect, useState } from "react";
import { PinkSwitch, StyledDiv, StyledRow } from "./MyPatientInfo.style";

import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";
import { TextFieldComponent } from "../TextFieldComponent/TextFieldComponent";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { useGetPatientQuery } from "../../redux/api/PatientsApi";

import { IPatientInfo } from "./MyPatientInfo.type";
import { ListItemText, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import male from "../../images/male.svg";
import female from "../../images/female.svg";
import { COLORS } from "../../core/constants/colors";
import { GlobalVlariables } from "../../core/constants/constant";
export const MyPatientInfo = (props: IPatientInfo) => {
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  const token = useSelector(selectCurrentToken) as string;
  const { t, i18n } = useTranslation();
  const { data } = useGetPatientQuery({
    token: token,
    patient_id: props.patientId ? props.patientId : -1,
    lang: i18n.language.substring(0, 2),
  });
  const [user, setUser] = useState({
    name: "",
    gender: null,
    birth_date: dayjs().format("YYYY-MM-DD"),
    is_pregnant: false,
    phone_number: "",
    email: "",
    since: "",
    doctor_name: "",
    next_follow_up: "",
  });
  useEffect(() => {
    setUser({
      ...user,
      name: data?.patient?.name,
      gender: data?.patient?.gender,
      birth_date: dayjs(data?.patient?.birth_date, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
      is_pregnant: data?.patient?.pregnant,
      phone_number: data?.patient?.phone_number,
      email: data?.patient?.email,
      since: data?.patient?.since,
      doctor_name: data?.patient?.doctor,
      next_follow_up: data?.patient?.next_follow_up,
    });
    props.setPatientName(data?.patient?.name);
    props.setPatientGender(data?.patient?.gender);
  }, [data]);

  return (
    <StyledDiv>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <StyledRow>
              <ListItemText
                primary={t("doctor")}
                primaryTypographyProps={{
                  style: { color: "green", fontSize: "17px" },
                }}
                secondary={user.doctor_name}
                secondaryTypographyProps={{
                  style: { color: "black" },
                }}
              />
              <ListItemText
                primary={t("user.patient_since")}
                primaryTypographyProps={{
                  style: { color: "green", fontSize: "17px" },
                }}
                secondary={user.since}
                secondaryTypographyProps={{
                  style: { color: "black" },
                }}
              />
              <ListItemText
                primary={t("next_follow_up")}
                primaryTypographyProps={{
                  style: { color: "green", fontSize: "17px" },
                }}
                secondary={user.next_follow_up}
                secondaryTypographyProps={{
                  style: { color: "black" },
                }}
              />
            </StyledRow>
            <br />
            {/* <Stack direction="row" spacing={1} alignItems="center"> */}

            <Grid item xs={12} sm={6}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{t("user.gender")}</Typography>
                <img
                  src={
                    user.gender == GlobalVlariables.GENDER.FEMALE
                      ? female
                      : male
                  }
                  alt={
                    user.gender == GlobalVlariables.GENDER.FEMALE
                      ? (t("gender.female") as string)
                      : (t("gender.male") as string)
                  }
                />
              </Stack>
            </Grid>
            <StyledRow>
              {user.gender == GlobalVlariables.GENDER.FEMALE && (
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>{t("pregnant")}</Typography>
                    <PinkSwitch
                      {...label}
                      value={user.is_pregnant}
                      checked={user.is_pregnant}
                    />
                  </Stack>
                </Grid>
              )}
            </StyledRow>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldComponent
              title={t("user.birth_date")}
              value={user.birth_date}
              name="birth_date"
              width="90%"
              backgroundColor={COLORS.WHITE}
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldComponent
              title={t("user.phone")}
              value={user.phone_number}
              name="phone_number"
              width="90%"
              backgroundColor={COLORS.WHITE}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldComponent
              title={t("user.email")}
              value={user.email}
              name="email"
              width="90%"
              backgroundColor={COLORS.WHITE}
            />
          </Grid>
        </Grid>
      </Box>
    </StyledDiv>
  );
};
