export const GlobalVlariables = {
  LETTERS: "0123456789ABCDEF",
  ROLE: {
    PATIENT: 2,
    DOCTOR: 1,
  },
  GENDER: {
    MALE: 0,
    FEMALE: 1,
  },
};
