import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { IFollowUpActions } from "./FollowUpActions.type";
import { ActionButton } from "./FollowUpActions.style";
import { StartConsultation } from "../StartConsultation/StartConsultation";

import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { useCancelMutation } from "../../redux/api/FollowUpApi";
import { dispatchSnackBar } from "../../core/utils/helpers/dispatchSnackBar";
import { COLORS } from "../../core/constants/colors";
import { UpdateFollowup } from "../UpdateFollowup/UpdateFollowup";
export const FollowUpActions = (props: IFollowUpActions) => {
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken) as string;

  // start immediate folllow up
  const [immmediate, setImmediate] = useState(false);
  const closeImmediate = () => {
    setImmediate(false);
  };
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleClose = () => {
    setShowConfirmDialog(false);
  };

  const [openUpdate, setOpenUpdate] = useState(false);

  const onCloseUpdate = () => {
    setOpenUpdate(false);
  };
  const [cancel, { isError: isCancelError, isSuccess: isCancelSuccess }] =
    useCancelMutation();
  //create user snackbar
  useEffect(() => {
    if (isCancelSuccess || isCancelError) {
      dispatchSnackBar(
        isCancelSuccess,
        isCancelError,
        t("snackbar_messages.success_cancel_follow_up"),
        t("snackbar_messages.error_cancel_follow_up")
      );
    }
  }, [isCancelSuccess, isCancelError]);
  return (
    <>
      {props?.status === 0 && (
        <Grid container spacing={1}>
          <Grid item md={5} xs={5}>
            <ActionButton
              textColor={
                props.isNextFollowUp
                  ? COLORS.GOLDEN_YELLOW
                  : COLORS.GREEN.DARK_OLIVE_GREEN
              }
              onClick={() => {
                props.isNextFollowUp ? setOpenUpdate(true) : setImmediate(true);
              }}
            >
              {props.isNextFollowUp ? t("follow_up.update") : t("submit")}
            </ActionButton>
          </Grid>
          <Grid item md={5} xs={5}>
            <ActionButton
              textColor={COLORS.BURGUNDY}
              onClick={() => {
                setShowConfirmDialog(true);
              }}
            >
              {t("cancel")}
            </ActionButton>
          </Grid>
        </Grid>
      )}
      <StartConsultation
        open={immmediate}
        onClose={closeImmediate}
        patientId={props.patientId}
        followUpId={props.followUpId}
        title={t("follow_up.submit") as string}
      />
      <ConfirmDialog
        handleClose={handleClose}
        open={showConfirmDialog}
        title={t("follow_up.cancel") as string}
        text={t("sure_to_cancel_follow_up") as string}
        onAgree={() =>
          cancel({
            followup_id: props.followUpId,
            token: token,
          })
        }
      ></ConfirmDialog>
      <UpdateFollowup
        open={openUpdate}
        onClose={onCloseUpdate}
        followUpId={props.followUpId}
        disableDate={false}
      />
    </>
  );
};
