import { useHomeQuery } from "../../redux/api/FollowUpApi";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { CircularProgress, Grid } from "@mui/material";
import {
  Item,
  StyledStack,
  StyledText,
  SubTitle,
  Title,
} from "./DoctorHomePage.style";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../core/constants/paths";
import { concatenate } from "../../core/utils/helpers/concatenate";

export const DoctorHomePage = () => {
  const { t, i18n } = useTranslation();
  const token = useSelector(selectCurrentToken) as string;
  const { data, isLoading } = useHomeQuery({
    token: token,
    lang: i18n.language.substring(0, 2),
  });
  const navigate = useNavigate();
  const followUpClick = (patientId: number) => {
    navigate(PATHS.MY_PATIENT_PROFILE.replace(":id", patientId.toString()));
  };
  return (
    <Grid container direction="column" spacing={2} sx={{ paddingTop: "2rem" }}>
      <Title>
        {t("welcome")} {data?.data?.name}
      </Title>
      <SubTitle>{t("doctor_home_page.today_follow_up")}</SubTitle>
      <StyledStack
        direction="row"
        sx={{
          flexWrap: "wrap",
          padding: "0rem 2rem 1rem 2rem",
          justifyContent: "justify",
        }}
      >
        {isLoading ? (
          <StyledText>
            <CircularProgress color="inherit" />
          </StyledText>
        ) : data?.data?.follow_ups?.today.length === 0 ? (
          <StyledText>{t("doctor_home_page.no_appointments")}</StyledText>
        ) : (
          data?.data?.follow_ups?.today.map((followup: any) => (
            <>
              <Item
                key={followup.id}
                onClick={() => followUpClick(followup.patient_id)}
              >
                {concatenate(
                  followup?.first_name,
                  followup?.last_name,
                  followup?.start_time,
                  followup?.end_time
                )}
              </Item>
            </>
          ))
        )}
      </StyledStack>
      <SubTitle>{t("doctor_home_page.to_submit")}</SubTitle>
      {isLoading ? (
        <StyledText>
          <CircularProgress color="inherit" />
        </StyledText>
      ) : data?.data?.follow_ups?.to_submit.length === 0 ? (
        <StyledText>{t("doctor_home_page.no_unsubmitted")}</StyledText>
      ) : (
        data?.data?.follow_ups?.to_submit?.map((followup: any) => (
          <StyledStack
            direction="column"
            spacing={{ xs: 1, md: 3 }}
            sx={{
              padding: "0rem 3rem 0rem 2rem",
            }}
          >
            <Item
              width="100%"
              onClick={() => followUpClick(followup.patient_id)}
            >
              {concatenate(
                followup?.patient?.first_name,
                followup?.patient?.last_name,
                followup?.start_time,
                followup?.end_time,
                followup?.date
              )}
            </Item>
          </StyledStack>
        ))
      )}
    </Grid>
  );
};
