import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import {
  OpenPopUpButton,
  RadioDiv,
  StyledGenderRow,
} from "./CreatePatient.style";
import { useAddPatientMutation } from "../../redux/api/PatientsApi";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import female from "../../images/female.svg";
import male from "../../images/male.svg";
import outlinedMale from "../../images/outlinedMale.svg";
import outlinedFemale from "../../images/outlinedFemale.svg";
import { useTranslation } from "react-i18next";
import { TextFieldComponent } from "../TextFieldComponent/TextFieldComponent";

import { FormDialog } from "../FormDialog/FormDialog";
import { emailValidation } from "../../core/utils/helpers/emailValidation";
import { phoneValidation } from "../../core/utils/helpers/phoneValidation";
import { getCustomError } from "../../core/utils/helpers/getCustomError";
import { dispatchSnackBar } from "../../core/utils/helpers/dispatchSnackBar";
import { GlobalVlariables } from "../../core/constants/constant";
export const CreatePatient = () => {
  const initValues = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    gender: "",
    birth_date: "",
    is_pregnant: "",
  };
  const initialValidation = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    gender: "",
    birth_date: "",
  };
  const [validation, setValidation] = useState(initialValidation);
  const token = useSelector(selectCurrentToken);
  const [values, setValues] = useState(initValues);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name == "gender" && e.target.value == "0") {
      setValues({ ...values, gender: "0", is_pregnant: "" });
    }
    setValues({ ...values, [e.target.name]: e.target.value });
    setValidation(initialValidation);
  };
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [addPatient, { isLoading, isError, isSuccess }] =
    useAddPatientMutation();
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidation({
      ...validation,
      first_name: values.first_name.length === 0 ? t("error.required") : "",
      last_name: values.last_name.length === 0 ? t("error.required") : "",
      phone_number:
        values.phone_number.length == 0
          ? t("error.required")
          : !phoneValidation(values.phone_number)
          ? t("error.phone_validation")
          : "",
      gender: values.gender.length == 0 ? t("error.required") : "",
      birth_date: values.birth_date.length == 0 ? t("error.required") : "",
      email:
        values.email.length > 0
          ? emailValidation(values.email)
            ? ""
            : t("error.email_validation")
          : "",
    });
    if (
      values.first_name.length > 0 &&
      values.last_name.length > 0 &&
      values.phone_number.length > 0 &&
      values.birth_date.length > 0 &&
      values.gender.length > 0 &&
      phoneValidation(values.phone_number) &&
      (values.email.length === 0 || emailValidation(values.email))
    ) {
      try {
        const response = await addPatient({
          ...values,
          token: token ? token : "",
          lang: i18n.language.substring(0, 2),
        }).unwrap();
        setValues(initValues);
        setOpen(false);
      } catch (err) {
        setValidation({
          ...validation,
          phone_number: getCustomError(err).data.phone_number
            ? (getCustomError(err).data.phone_number?.[0] as string)
            : "",
          email: getCustomError(err).data.email
            ? (getCustomError(err).data.email?.[0] as string)
            : "",
        });
      }
    }
  };
  const { t, i18n } = useTranslation();
  //create user snackbar
  useEffect(() => {
    if (isSuccess || isError) {
      dispatchSnackBar(
        isSuccess,
        isError,
        t("snackbar_messages.success_create_patient"),
        t("snackbar_messages.error_create_patient")
      );
    }
  }, [isSuccess, isError]);
  return (
    <div>
      <Tooltip title={t("create_patient")}>
        <OpenPopUpButton variant="outlined" onClick={handleClickOpen}>
          {t("create")}
        </OpenPopUpButton>
      </Tooltip>
      <FormDialog open={open} title={t("create_patient")} onClose={handleClose}>
        <form onSubmit={onSubmit}>
          <TextFieldComponent
            autofocus
            onChange={onChange}
            name="first_name"
            value={values.first_name}
            title={t("user.first_name")}
            validation={validation.first_name}
          />
          <TextFieldComponent
            onChange={onChange}
            name="last_name"
            value={values.last_name}
            title={t("user.last_name")}
            validation={validation.last_name}
          />
          <br />

          <StyledGenderRow>
            <span>Gender:</span>
            <RadioDiv>
              <Checkbox
                name="gender"
                checked={
                  values.gender == GlobalVlariables.GENDER.FEMALE.toString()
                }
                value={values.gender}
                icon={
                  <img
                    src={outlinedFemale}
                    alt={t("gender.female") as string}
                  />
                }
                checkedIcon={
                  <img src={female} alt={t("gender.female") as string} />
                }
                onChange={(e) => {
                  setValues({
                    ...values,
                    gender: e.target.checked
                      ? GlobalVlariables.GENDER.FEMALE.toString()
                      : "",
                  });
                }}
              />
              <Checkbox
                name="gender"
                checked={
                  values.gender == GlobalVlariables.GENDER.MALE.toString()
                }
                value={values.gender}
                icon={
                  <img src={outlinedMale} alt={t("gender.male") as string} />
                }
                checkedIcon={
                  <img src={male} alt={t("gender.male") as string} />
                }
                onChange={(e) => {
                  setValues({
                    ...values,
                    gender: e.target.checked
                      ? GlobalVlariables.GENDER.MALE.toString()
                      : "",
                  });
                }}
              />
            </RadioDiv>
          </StyledGenderRow>
          <span style={{ color: "red", fontSize: "12px" }}>
            {validation.gender}
          </span>

          {values.gender == GlobalVlariables.GENDER.FEMALE.toString() && (
            <RadioDiv mLeft="4rem">
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_pregnant"
                    checked={values.is_pregnant == "1"}
                    value={values.is_pregnant}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        is_pregnant: e.target.checked ? "1" : "0",
                      });
                    }}
                  />
                }
                label={t("pregnant")}
              />
            </RadioDiv>
          )}
          <TextFieldComponent
            name="phone_number"
            onChange={onChange}
            value={values.phone_number}
            title={t("user.phone")}
            validation={validation.phone_number}
          />
          <TextFieldComponent
            name="birth_date"
            onChange={onChange}
            value={values.birth_date}
            title={t("user.birth_date")}
            type="date"
            validation={validation.birth_date}
          />

          <TextFieldComponent
            name="email"
            onChange={onChange}
            value={values.email}
            title={t("user.email")}
            validation={validation.email}
          />
          <DialogActions>
            <Button
              color="inherit"
              onClick={() => {
                setOpen(false);
                setValues(initValues);
                setValidation(initialValidation);
              }}
            >
              {t("cancel")}
            </Button>
            <Button color="success" type="submit">
              {isLoading && <CircularProgress color="success" size={15} />}
              {t("save")}
            </Button>
          </DialogActions>
        </form>
      </FormDialog>
    </div>
  );
};
