import { styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledMessage = styled("div")({
  fontSize: "0.9rem",
  marginBottom: "2rem",
  color: `${COLORS.GRAY.DARK_GRAY}`,
  fontFamily: "georgia",
  textAlign: "start",
});
