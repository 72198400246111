import { styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

export const ErrorTitle = styled("h1")(({ theme }) => ({
  fontSize: "2.5rem",
  fontWeight: "bold",
  color: `${COLORS.GRAY.DARK_GRAY}`,
  margin: "0",

  [theme.breakpoints.up("md")]: {
    fontSize: "4rem",
  },
}));

export const ErrorMessage = styled("h3")(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  color: `${COLORS.GRAY.CHARCOAL_GRAY}`,
  marginTop: "0",
  marginBottom: "2rem",

  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
    marginBottom: "3rem",
  },
}));
