import { Doctor } from "../models/doctor.interface";
import { Patient } from "../models/patient.interface";

export interface TokenData {
  access_token: string;
  expires_in: number;
}
// Save Login Data in local storage
export const saveTokenToLocalStorage = (token: TokenData) => {
  localStorage.setItem("token", JSON.stringify(token));
};
export const saveUserToLocalStorage = (user: Doctor | Patient) => {
  localStorage.setItem("user", JSON.stringify(user));
};
export const saveRoleToLocalStorage = (role: number) => {
  localStorage.setItem("role", JSON.stringify(role));
};
//get Login Data from local storage
export const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token");
  return token ? JSON.parse(token) : null;
};

export const getUserFromLocalStorage = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

export const getRoleFromLocalStorage = () => {
  const role = localStorage.getItem("role");
  return role ? JSON.parse(role) : null;
};

//remove Login Data from local storage
export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};

export const removeTokenFromLocalStorage = () => {
  localStorage.removeItem("token");
};

export const removeRoleFromLocalStorage = () => {
  localStorage.removeItem("role");
};

export const isTokenExpired = (): boolean => {
  const token = getTokenFromLocalStorage();

  if (!token) {
    return true; // Token not found, so it is expired
  }
  const currentTime = Math.floor(Date.now() / 1000); // convert milliseconds to seconds

  return currentTime > token.expires_in;
};
