import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BoxComponent } from "../../components/BoxComponent/BoxComponent";
import VpnKeySharpIcon from "@mui/icons-material/VpnKeySharp";
import { ButtonComponent } from "../../components/ButtonComponent/ButtonComponent";
import { useTranslation } from "react-i18next";
import { useResetPasswordMutation } from "../../redux/api/DoctorAuthApi";
import { useNavigate } from "react-router-dom";
import { getCustomError } from "../../core/utils/helpers/getCustomError";
import { AppLayout } from "../../layouts/AppLayout/AppLayout";
import { Loader } from "../../components/loader/Loader";
import { StyledTextField } from "./ResetPassword.style";
import { PATHS } from "../../core/constants/paths";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    token: "",
    email: "",
  });
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("email");
    const token = searchParams.get("token");
    setState({
      ...state,
      email: searchParams.get("email") || "",
      token: searchParams.get("token") || "",
    });
  }, []);
  const [resetPassword, { isLoading, isSuccess, isError }] =
    useResetPasswordMutation();
  const [values, setValues] = useState({
    password: "",
    confirmpassword: "",
    showPassword: false,
  });

  const [error, setError] = useState("");
  const [validation, setValidation] = useState({
    password: "",
    confirm_password: "",
  });
  const { t, i18n } = useTranslation();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setError("");
    setValidation({ password: "", confirm_password: "" });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setValidation({
      password: values.password.length != 0 ? "" : t("error.required"),
      confirm_password:
        values.confirmpassword.length == 0
          ? t("error.required")
          : values.password != values.confirmpassword
          ? t("error.password_match")
          : "",
    });
    if (
      values.password.length != 0 &&
      values.confirmpassword.length != 0 &&
      values.password == values.confirmpassword
    ) {
      try {
        const response = await resetPassword({
          token: state.token,
          email: state.email,
          password: values.password,
          password_confirmation: values.confirmpassword,
          lang: i18n.language.substring(0, 2),
        }).unwrap();
        navigate(PATHS.HOME);
      } catch (err) {
        setError(getCustomError(err).data.password?.[0] as string);
      }
    }
  };

  return (
    <AppLayout isAuth={true}>
      <BoxComponent title={t("auth.reset_password") as string}>
        {isLoading && <Loader />}
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <StyledTextField
            placeholder={t("user.password") as string}
            value={values.password}
            onChange={onChange}
            margin="normal"
            required
            fullWidth
            name="password"
            type={values.showPassword ? "text" : "password"}
            id="password"
            error={validation.password != ""}
            helperText={validation.password}
            autoComplete="current-password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeySharpIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            placeholder={t("auth.confirm_password") as string}
            value={values.confirmpassword}
            onChange={onChange}
            margin="normal"
            required
            fullWidth
            error={validation.confirm_password != "" || error != ""}
            helperText={
              validation.confirm_password != ""
                ? validation.confirm_password
                : error
            }
            name="confirmpassword"
            type="password"
            id="ConfirmPassword"
            autoComplete="current-password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeySharpIcon />
                </InputAdornment>
              ),
            }}
          />
          <ButtonComponent title={t("auth.submit") as string} />
        </Box>
      </BoxComponent>
    </AppLayout>
  );
};
