import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { HistoryConstants } from "./History.constants";
import { StartConsultationConstants } from "../StartConsultation/StartConsultation.constants";
import {
  CriteriaTitle,
  CriteriaValue,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledTitle,
} from "./History.style";
import { NextFollowUp } from "../NextFollowUp/NextFollowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Grid, Tooltip, Typography } from "@mui/material";
import { useHistoryQuery } from "../../redux/api/ByPatientFollowUpApi";
import { Details } from "../Details/Details";
export const History = () => {
  const { t, i18n } = useTranslation();
  const token = useSelector(selectCurrentToken) as string;
  const { data: history } = useHistoryQuery({
    token: token,
    lang: i18n.language.substring(0, 2),
  });
  const [STATUS_MESSAGES, STATUS_COLOR] = HistoryConstants();
  const { CONSULTATION: CRITERIA } = StartConsultationConstants();
  const [expandedAccordions, setExpandedAccordions] = useState<boolean[]>(
    Array(history?.follow_ups?.previous.length).fill(false)
  );

  const handleAccordionChange = (index: number) => {
    setExpandedAccordions((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };
  return (
    <>
      {/* Next Appointment */}
      <StyledAccordion>
        <StyledAccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <NextFollowUp
            date={history?.follow_ups?.next?.date}
            followUpId={history?.follow_ups?.next?.id}
          />
        </StyledAccordionSummary>
      </StyledAccordion>

      {/* Recent follow up */}
      {history?.follow_ups?.recent && (
        <StyledAccordion expanded={history?.follow_ups?.recent?.status === 1}>
          <StyledAccordionSummary
            expandIcon={
              history?.follow_ups?.recent?.status === 1 && <ExpandMoreIcon />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography>{history?.follow_ups?.recent?.date}</Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={STATUS_MESSAGES[history?.follow_ups?.recent?.status]}
                  >
                    <FiberManualRecordIcon
                      color={
                        STATUS_COLOR[history?.follow_ups?.recent?.status] as
                          | "warning"
                          | "success"
                          | "error"
                      }
                      fontSize="small"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <>
              {history?.follow_ups?.recent?.status === 1 && (
                <>
                  <StyledTitle>{t("follow_up.examination")}</StyledTitle>
                  {CRITERIA?.map((attribute) => (
                    <Grid
                      container
                      sx={{
                        position: "relative",
                        left: "2rem",
                        maxWidth: "90%",
                      }}
                    >
                      <Grid item md={4} xs={4}>
                        <CriteriaTitle>{attribute.title}:</CriteriaTitle>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <CriteriaValue>
                          {history?.follow_ups?.recent[attribute.name]}
                        </CriteriaValue>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            </>
          </StyledAccordionDetails>
        </StyledAccordion>
      )}
      {/* previous follow ups */}
      {history?.follow_ups?.previous.length > 0 &&
        history?.follow_ups?.previous.map((followUp: any) => {
          return (
            <StyledAccordion
              onChange={() => handleAccordionChange(followUp.id)}
            >
              <StyledAccordionSummary
                expandIcon={followUp?.status === 1 && <ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography>{followUp?.date}</Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title={STATUS_MESSAGES[followUp?.status]}>
                        <FiberManualRecordIcon
                          color={
                            STATUS_COLOR[followUp?.status] as
                              | "warning"
                              | "success"
                              | "error"
                          }
                          fontSize="small"
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </>
              </StyledAccordionSummary>
              {expandedAccordions[followUp?.id] && followUp?.status === 1 && (
                <StyledAccordionDetails>
                  <Details followUpId={followUp?.id} />
                </StyledAccordionDetails>
              )}
            </StyledAccordion>
          );
        })}
    </>
  );
};
