import { FormDialog } from "../FormDialog/FormDialog";
import { WeekCalendar } from "../WeekCalendar/WeekCalendar";
import { ITasks } from "./AddTasksModal.type";

export const AddTasksModal = (props: ITasks) => {
  return (
    <FormDialog
      title="Add Tasks"
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
    >
      <WeekCalendar patientId={props.patientId} isCreate={true} />
    </FormDialog>
  );
};
