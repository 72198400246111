import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";
import { selectCurrentRole } from "../../redux/slices/UserAuth";
import { GlobalVlariables } from "../../core/constants/constant";
import { PATHS } from "../../core/constants/paths";

interface Props {
  children: ReactNode;
}

function DoctorGuard(props: Props) {
  const { children } = props;
  const isLoggedIn = useLoggedIn();
  const role = useSelector(selectCurrentRole);

  if (!isLoggedIn) {
    return <Navigate to={PATHS.PATIENT_AUTH} replace />;
  }
  if (role != GlobalVlariables.ROLE.PATIENT) {
    return <Navigate to={PATHS.HOME} replace />;
  }

  return <>{children}</>;
}

export default DoctorGuard;
