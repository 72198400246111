import react from "react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DOCTOR_BASE_URL, ENDPOINTS } from "../../core/constants/paths";
import { RootState } from "../store";
import { IStartFollowUp } from "../../shared/interfaces/FollowUp";

const date = new Date();
export const followupApi = createApi({
  reducerPath: "followupApi",
  baseQuery: fetchBaseQuery({
    baseUrl: DOCTOR_BASE_URL,
  }),
  tagTypes: ["followups"],
  endpoints: (builder) => ({
    followups: builder.query({
      query: (parameters: {
        token: string;
        date: string;

        to?: string;
      }) => ({
        url: ENDPOINTS.FETCH_FOLLOWUPS,
        method: "GET",
        params: {
          date: parameters.date,
          to: parameters.to,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      providesTags: ["followups"],
    }),
    addFollowUp: builder.mutation({
      query: (params: {
        token: string;
        start_time: string;
        end_time: string;
        date: string;
        patient_id: number;
        lang?: string;
      }) => ({
        url: `${ENDPOINTS.FETCH_PATIENTS}/${params.patient_id}/${ENDPOINTS.CREATE_PATIENT_FOLLOWUP}`,
        method: "POST",
        body: {
          start_time: params.start_time,
          end_time: params.end_time,
          date: params.date,
          lang: params.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      invalidatesTags: ["followups"],
    }),
    immediateFollowUp: builder.mutation({
      query: (params: {
        token: string;
        patient_id: number;
        lang?: string;
      }) => ({
        url: `${ENDPOINTS.FETCH_PATIENTS}/${params.patient_id}/${ENDPOINTS.CREATE_IMMEDIATE_FOLLOWUP}`,
        method: "POST",
        body: {
          lang: params.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      invalidatesTags: ["followups"],
    }),
    followUpSubmit: builder.mutation({
      query: (params: IStartFollowUp) => ({
        url: `${ENDPOINTS.FETCH_FOLLOWUPS}/${params.followup_id}`,
        method: "POST",
        body: {
          weight: params.weight,
          target_weight: params.target_weight,
          height: params.height,
          heart_pressure: params.heart_pressure,
          allergy: params.allergy,
          current_medicament: params.current_medicament,
          body_fat: params.body_fat,
          glucose_level: params.glucose_level,
          cholesterol_level: params.cholesterol_level,
          notes: params.notes,
          lang: params.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      invalidatesTags: ["followups"],
    }),
    deleteFollowup: builder.mutation({
      query: (params: { followup_id: number; token: string }) => ({
        url: `${ENDPOINTS.FETCH_FOLLOWUPS}/${params.followup_id}`,
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      invalidatesTags: ["followups"],
    }),
    followupsCount: builder.query({
      query: (params: { token: string; month: string; year: string }) => ({
        url: `${ENDPOINTS.FETCH_FOLLOWUPS}/daily-count`,
        method: "GET",
        params: {
          month: params.month,
          year: params.year,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      providesTags: ["followups"],
    }),
    followupUpdate: builder.mutation({
      query: (params: {
        token: string;
        followup_id: number;
        start_time: string;
        end_time: string;
        date: string;
        lang?: string;
      }) => ({
        url: `${ENDPOINTS.FETCH_FOLLOWUPS}/${params.followup_id}`,
        method: "PUT",
        body: {
          date: params.date,
          start_time: params.start_time,
          end_time: params.end_time,
          lang: params.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      invalidatesTags: ["followups"],
    }),
    byDoctorHistory: builder.query({
      query: (parameters: {
        token: string;
        patient_id: number;
        lang?: string;
      }) => ({
        url: `${ENDPOINTS.FETCH_PATIENTS}/${parameters.patient_id}/${ENDPOINTS.PROFILE}/${ENDPOINTS.HISTORY}`,
        method: "GET",
        params: {
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      providesTags: ["followups"],
    }),
    byDoctorDetails: builder.query({
      query: (parameters: {
        token: string;
        lang?: string;
        followup_id: number;
      }) => ({
        url: `${ENDPOINTS.FETCH_FOLLOWUPS}/${parameters.followup_id}`,
        method: "GET",
        params: {
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      providesTags: ["followups"],
    }),
    cancel: builder.mutation({
      query: (params: { followup_id: number; token: string }) => ({
        url: `${ENDPOINTS.FETCH_FOLLOWUPS}/${params.followup_id}/${ENDPOINTS.FOLLOW_UP_CANCEL}`,
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + params.token,
        },
      }),
      invalidatesTags: ["followups"],
    }),

    Home: builder.query({
      query: (parameters: { token: string; lang?: string }) => ({
        url: `${ENDPOINTS.HOME}`,
        method: "GET",
        params: {
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      providesTags: ["followups"],
    }),
    byDoctorTracking: builder.query({
      query: (parameters: {
        token: string;
        patient_id: number;
        lang?: string;
      }) => ({
        url: `${ENDPOINTS.FETCH_PATIENTS}/${parameters.patient_id}/${ENDPOINTS.PROFILE}/${ENDPOINTS.TRACKING}`,
        method: "GET",
        params: {
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      providesTags: ["followups"],
    }),
  }),
});
export const {
  useByDoctorTrackingQuery,
  useByDoctorDetailsQuery,
  useByDoctorHistoryQuery,
  useCancelMutation,
  useFollowupUpdateMutation,
  useImmediateFollowUpMutation,
  useFollowupsCountQuery,
  useDeleteFollowupMutation,
  useFollowUpSubmitMutation,
  useFollowupsQuery,
  useAddFollowUpMutation,
  useHomeQuery,
} = followupApi;
