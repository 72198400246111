import {
  IGenericGuestForm,
  IStepOne,
  IStepThree,
  IStepTwo,
  IStepsData,
} from "./GenericGuestForm.type";
import {
  StyledForm,
  StyledQuestion,
  StyledQuestions,
  StyledRadio,
  StyledTextField,
  StyledButton,
  StyledCardActions,
} from "./GenericGuestForm.style";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  RadioGroup,
} from "@mui/material";
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../core/constants/colors";
import { GeneratedProgram } from "../GeneratedProgram/GeneratedProgram";

const validationRules = {
  height: {
    required: {
      value: true,
      message: "this field is required",
    },
  },
  weight: {
    required: {
      value: true,
      message: "this field is required",
    },
  },
  age: {
    required: {
      value: true,
      message: "this field is required",
    },
    min: {
      value: 18,
      message:
        "Sorry! only users up to 18 years old have access to this fonctionnality",
    },
  },
  gender: {
    required: {
      value: true,
      message: "this field is required",
    },
  },
  goals: {
    required: {
      value: true,
      message: "this field is required",
    },
  },
};
const GenericGuestForm = ({
  content,
  handleNext,
  handlePrevious,
  currentStep,
}: IGenericGuestForm) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStepsData>({
    defaultValues: {
      days: [],
      goals: [],
      illness: [],
    },
  });
  const [submitted, setSubmitted] = useState(false);

  const [guestData, setGuestData] = useState<IStepsData>({
    height: 0,
    weight: 0,
    age: 0,
    gender: "",
    days: [],
    meals_to_avoid: "",
    goals: [],
    allergy: "",
    illness: [],
    pregnant: "",
  });
  const onSubmit: SubmitHandler<IStepsData> = (data) => {
    setSubmitted(true);
    setGuestData({ ...data });
  };
  const { t } = useTranslation();
  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        {currentStep < 3 && !submitted && (
          <StyledQuestions>
            {content.questions.map((question) => (
              <>
                <StyledQuestion>{question.question}</StyledQuestion>
                {(() => {
                  switch (question.type) {
                    case "textfield":
                      return (
                        <Controller
                          key={question.name}
                          name={
                            question.name as keyof (
                              | IStepOne
                              | IStepTwo
                              | IStepThree
                            )
                          }
                          control={control}
                          render={({ field }) => (
                            <>
                              <StyledTextField
                                key={question.name}
                                placeholder={
                                  question.name === "meals_to_avoid"
                                    ? "Meal 1 , Meal 2 , ..."
                                    : question.name === "allergy"
                                    ? "Allergy 1 , Allergy 2 , ..."
                                    : ""
                                }
                                type={
                                  question.name != "meals_to_avoid" &&
                                  question.name != "allergy"
                                    ? "number"
                                    : "string"
                                }
                                {...field}
                              />
                              {errors && question.name in errors && (
                                <p>
                                  {
                                    errors[
                                      question.name as keyof FieldErrors<
                                        IStepOne | IStepTwo | IStepThree
                                      >
                                    ]?.message
                                  }
                                </p>
                              )}
                            </>
                          )}
                          rules={{
                            required: {
                              value: true,
                              message: t("validation.address_required"),
                            },
                          }}
                        />
                      );
                    case "select":
                      return (
                        <Controller
                          key={question.name}
                          name={
                            question.name as keyof (
                              | IStepOne
                              | IStepTwo
                              | IStepThree
                            )
                          }
                          control={control}
                          render={({ field }) => (
                            <RadioGroup
                              key={question.name}
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              {question.options?.map((option) => (
                                <FormControlLabel
                                  key={option.value}
                                  {...field}
                                  control={
                                    <StyledRadio
                                      value={option.value}
                                      checked={field.value == option.value}
                                    />
                                  }
                                  label={option.text}
                                  sx={{
                                    paddingLeft: "2rem",
                                    "& .MuiTypography-root": {
                                      fontSize: "0.75rem",
                                    },
                                  }}
                                />
                              ))}
                            </RadioGroup>
                          )}
                          rules={
                            validationRules[
                              question.name as keyof (
                                | IStepOne
                                | IStepTwo
                                | IStepThree
                              )
                            ]
                          }
                        />
                      );
                    case "checkbox":
                      return (
                        <Controller
                          key={question.name}
                          name={
                            question.name as keyof (
                              | IStepOne
                              | IStepTwo
                              | IStepThree
                            )
                          }
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <FormGroup key={question.name}>
                              {question.options?.map((option) => (
                                <FormControlLabel
                                  key={option.value}
                                  name={question.name}
                                  control={
                                    <Checkbox
                                      key={option.value}
                                      value={option}
                                      checked={(value as string[])?.includes(
                                        option.value
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          onChange([
                                            ...(value as string[]),
                                            option.value,
                                          ]);
                                        } else {
                                          onChange(
                                            (value as string[])?.filter(
                                              (value: any) =>
                                                value !== option.value
                                            )
                                          );
                                        }
                                      }}
                                      sx={{
                                        "&.MuiCheckbox-root": {
                                          "&.Mui-checked": {
                                            color: `${COLORS.GREEN.DARK_GREEN_OPACITY_90}`,
                                          },
                                        },
                                      }}
                                    />
                                  }
                                  label={option.text}
                                  sx={{
                                    alignItems: "start",
                                    textAlign: "-webkit-left",
                                    paddingLeft: "2rem",
                                    "& .MuiTypography-root": {
                                      fontSize: "0.75rem",
                                      paddingLeft: "0.5rem",
                                    },
                                    "& .MuiCheckbox-root": { padding: "0" },
                                  }}
                                />
                              ))}
                            </FormGroup>
                          )}
                          rules={
                            validationRules[
                              question.name as keyof (
                                | IStepOne
                                | IStepTwo
                                | IStepThree
                              )
                            ]
                          }
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </>
            ))}
          </StyledQuestions>
        )}

        {currentStep === 3 && submitted && <GeneratedProgram {...guestData} />}

        <StyledCardActions>
          <StyledButton onClick={handlePrevious} disabled={currentStep === 3}>
            <ArrowBackIosIcon />
            {t("guest_form.previous")}
          </StyledButton>
          <StyledButton
            onClick={() => {
              handleNext(true);
            }}
            type={currentStep > 2 ? "submit" : "button"}
          >
            {currentStep >= 2
              ? t("guest_form.steps.generate")
              : t("guest_form.next")}
            <ArrowForwardIosIcon />
          </StyledButton>
        </StyledCardActions>
      </StyledForm>
    </>
  );
};

export default GenericGuestForm;
