import { Button, TextField, styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledTitle = styled("div")({
  fontWeight: "bold",
  color: `${COLORS.GREEN.DARK_GREEN_OPACITY_90}`,
  fontSize: "1.3rem",
  padding: "1rem",
});

export const StyledTextField = styled(TextField)({
  width: "22rem",
  height: "2rem",
  "& .MuiOutlinedInput-root": {
    height: "2rem",
    "&.Mui-focused fieldset": {
      borderColor: `${COLORS.GREEN.DARK_GREEN_OPACITY_90}`,
    },
    "&.Mui-focused ": {
      borderColor: `${COLORS.GREEN.DARK_GREEN_OPACITY_90}`,
    },
  },
});
export const DayTitle = styled("div")({
  fontWeight: "bold",
  color: `${COLORS.DARK_PURPLE}`,
  fontSize: "1.1rem",
});
export const SectionTitle = styled("div")({
  fontWeight: "bold",
  color: `${COLORS.BLACK_OPACITY_54}`,
  fontSize: "0.9rem",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  textAlign: "start",
  padding: "0.5rem",
});
export const StyledUl = styled("ul")({
  display: "flex",
  flexDirection: "column",
  textAlign: "start",
  fontSize: "0.8rem",
  fontWeight: "bold",
});

export const DownloadSection = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "1rem",
  alignItems: "center",
  maxWidth: "27rem",
});
