import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import {
  useTasksQuery,
  useToggleCompleteMutation,
} from "../../redux/api/ByPatientTaskApi";
import { useTranslation } from "react-i18next";
import { ITasks } from "./Tasks.type";
import {
  Checkbox,
  Container,
  IconButton,
  ListItem,
  ListItemText,
} from "@mui/material";
import checked from "../../images/checked.svg";
import notChecked from "../../images/notChecked.svg";
import { useEffect, useState } from "react";
import { dispatchSnackBar } from "../../core/utils/helpers/dispatchSnackBar";

export const Tasks = (props: ITasks) => {
  const { t, i18n } = useTranslation();
  const token = useSelector(selectCurrentToken) as string;
  const { data: tasks } = useTasksQuery({
    token: token,
    date: props.date,
    lang: i18n.language.substring(0, 2),
  });
  const [toggleComplete, { isError }] = useToggleCompleteMutation();
  const [error, setError] = useState("");
  const updateStatus = async (taskId: number) => {
    const result = await toggleComplete({
      token: token,
      lang: i18n.language.substring(0, 2),
      task_id: taskId,
    })
      .unwrap()
      .then()
      .catch((err: any) => {
        setError(err?.data?.message);
      });
  };
  useEffect(() => {
    if (isError) {
      dispatchSnackBar(false, isError, "", error);
    }
  }, [isError, error]);
  return (
    <Container sx={{ backgroundColor: "white", marginTop: "2rem" }}>
      {Array.isArray(tasks?.tasks) &&
        tasks?.tasks?.map((task: any) => (
          <ListItem
            key={task.id}
            secondaryAction={
              <IconButton edge="end" aria-label="comments">
                <Checkbox
                  checked={task?.complete == "1"}
                  icon={
                    <img src={notChecked} alt={t("uncompleted") as string} />
                  }
                  checkedIcon={
                    <img src={checked} alt={t("completed") as string} />
                  }
                  onChange={(e) => {
                    updateStatus(task.id);
                  }}
                />
              </IconButton>
            }
          >
            <ListItemText primary={task.content} />
          </ListItem>
        ))}
    </Container>
  );
};
