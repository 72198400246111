import react, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  InputAdornment,
  IconButton,
  TextField,
  FormControlLabel,
} from "@mui/material";
import DraftsSharpIcon from "@mui/icons-material/DraftsSharp";
import VpnKeySharpIcon from "@mui/icons-material/VpnKeySharp";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { StyledGrid, StyledTextField } from "./Login.style";
import { BoxComponent } from "../../components/BoxComponent/BoxComponent";
import { ButtonComponent } from "../../components/ButtonComponent/ButtonComponent";
import { useTranslation } from "react-i18next";
import { useLoginMutation } from "../../redux/api/DoctorAuthApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppLayout } from "../../layouts/AppLayout/AppLayout";
import { Loader } from "../../components/loader/Loader";
import { setUser } from "../../redux/slices/UserAuth";
import { emailValidation } from "../../core/utils/helpers/emailValidation";
import { getCustomError } from "../../core/utils/helpers/getCustomError";
import { PATHS } from "../../core/constants/paths";
export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, { isSuccess, isError, isLoading }] = useLoginMutation();
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [error, setError] = useState({
    email: "",
    password: "",
    requiredPassword: "",
    requiredEmail: "",
  });
  const { t, i18n } = useTranslation();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setError({
      email: "",
      password: "",
      requiredPassword: "",
      requiredEmail: "",
    });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const validationFunction = () => {
    if (values.email.length == 0) {
      setError((prevState) => ({
        ...prevState,
        requiredEmail: t("error.required") || "",
      }));
    } else if (!emailValidation(values.email)) {
      setError((prevState) => ({
        ...prevState,
        email: t("error.email_validation") || "",
      }));
    }
    if (values.password.length == 0) {
      setError((prevState) => ({
        ...prevState,
        requiredPassword: t("error.required") || "",
      }));
    }
    if (
      values.password.length == 0 ||
      !emailValidation(values.email) ||
      values.email.length == 0
    ) {
      return false;
    }
    return true;
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validationFunction()) {
      try {
        const response = await login({
          email: values.email,
          password: values.password,
          lang: i18n.language.substring(0, 2),
        }).unwrap();
        const { doctor, access_token, token_type, expires_in } = response;
        dispatch(
          setUser({
            user: doctor,
            role: "1",
            jwt: access_token,
            expires_in: expires_in,
          })
        );
        navigate(PATHS.HOME);
      } catch (err) {
        const required_email = getCustomError(err).data.email
          ? (getCustomError(err).data.email?.[0] as string)
          : "";
        const required_password = getCustomError(err).data.password
          ? (getCustomError(err).data.password?.[0] as string)
          : "";
        setError({
          email: getCustomError(err).data.emailError
            ? (getCustomError(err).data.emailError as string)
            : "",
          password: getCustomError(err).data.passwordError
            ? (getCustomError(err).data.passwordError as string)
            : "",
          requiredEmail: required_email,
          requiredPassword: required_password,
        });
      }
    }
  };
  return (
    <AppLayout isAuth={true}>
      <BoxComponent title={t("auth.signin") as string}>
        {isLoading && <Loader />}
        <Box
          sx={{
            width: "100%",
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <StyledTextField
            placeholder="Email"
            value={values.email}
            onChange={onChange}
            margin="normal"
            fullWidth
            error={error.requiredEmail != "" || error.email != ""}
            helperText={
              error.requiredEmail != "" ? error.requiredEmail : error.email
            }
            id="email"
            name="email"
            autoComplete="email"
            sx={{ $focus: { borderColor: "green" } }}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DraftsSharpIcon />
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            placeholder={t("user.password") as string}
            value={values.password}
            onChange={onChange}
            margin="normal"
            fullWidth
            error={error.requiredPassword !== "" || error.password !== ""}
            helperText={
              error.requiredPassword !== ""
                ? error.requiredPassword
                : error.password
            }
            name="password"
            type={values.showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeySharpIcon />
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <ButtonComponent title={t("auth.signin") as string} width="100%" />
          <StyledGrid>
            <Grid item xs>
              <Link href="/forget-password" variant="body2">
                {t("auth.forgot_passord") as string}?
              </Link>
            </Grid>
          </StyledGrid>
        </Box>
      </BoxComponent>
    </AppLayout>
  );
};
