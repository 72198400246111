import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  SwipeableDrawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  MenuItem,
} from "@mui/material";
import {
  NavlinkStyled,
  StyleDiv,
  StyledImage,
  StyledSideBarButton,
  StyledTitle,
} from "./Sidebar.style";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  logOut,
  selectCurrentRole,
  selectCurrentToken,
  selectCurrentUser,
} from "../../redux/slices/UserAuth";
import { SideBarConstants } from "./SideBar.constants";
import logo from "../../images/logo.svg";
import Logout from "@mui/icons-material/Logout";
import { Doctor } from "../../core/models/doctor.interface";
import { Patient } from "../../core/models/patient.interface";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../redux/api/DoctorAuthApi";
import { usePatientLogoutMutation } from "../../redux/api/PatientAuthApi";
import { COLORS } from "../../core/constants/colors";
import useSmallScreen from "../../hooks/useSmallScreen";
import { GlobalVlariables } from "../../core/constants/constant";
import { PATHS } from "../../core/constants/paths";
export const Sidebar = () => {
  const { t } = useTranslation();
  const role = useSelector(selectCurrentRole);
  const [PATIENT_MENU_ITEMS, DOCTOR_MENU_ITEMS, DOCTOR_MENU] =
    SideBarConstants();
  const menuItems =
    role == GlobalVlariables.ROLE.DOCTOR
      ? DOCTOR_MENU_ITEMS
      : role == GlobalVlariables.ROLE.PATIENT
      ? PATIENT_MENU_ITEMS
      : [];
  const menu = role == GlobalVlariables.ROLE.DOCTOR ? DOCTOR_MENU : [];
  const [state, setState] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState(!state);
    };
  const [logout] = useLogoutMutation();
  const [patientLogout] = usePatientLogoutMutation();
  const user = useSelector(selectCurrentUser);

  const token = useSelector(selectCurrentToken);
  const [userState, setUserState] = useState(user as Doctor | Patient | null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogoutClick = async () => {
    if (token) {
      try {
        if (role == GlobalVlariables.ROLE.DOCTOR) {
          const result = await logout(token);
        } else if (role == GlobalVlariables.ROLE.PATIENT) {
          const result = await patientLogout(token);
        }
        dispatch(logOut(userState));
        window.location.reload();
      } catch (error) {}
    }
    navigate(PATHS.HOME);
  };
  const list = () => (
    <Box
      sx={{
        width: 250,
        height: "100vh",
        backgroundColor: `${COLORS.GREEN.DARK_GREEN_OPACITY_90}`,
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <StyledTitle>
        <StyledImage>
          <img src={logo} alt="Mon logo" />
        </StyledImage>
      </StyledTitle>

      <List>
        {menuItems.map((item, index) => (
          <NavlinkStyled to={item.path} key={index}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon sx={{ color: `${COLORS.WHITE} ` }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </NavlinkStyled>
        ))}
      </List>
      {role == GlobalVlariables.ROLE.DOCTOR && (
        <Divider variant="middle" sx={{ borderColor: `${COLORS.WHITE} ` }} />
      )}
      <List>
        {menu.map((item, index) => (
          <NavlinkStyled to={item.path} key={index}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon sx={{ color: `${COLORS.WHITE} ` }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </NavlinkStyled>
        ))}
      </List>
      <StyleDiv>
        <Divider variant="middle" sx={{ borderColor: `${COLORS.WHITE} ` }} />
        <ListItem disablePadding onClick={handleLogoutClick}>
          <ListItemButton>
            <ListItemIcon sx={{ color: `${COLORS.WHITE} ` }}>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("auth.logout")} />
          </ListItemButton>
        </ListItem>
      </StyleDiv>
    </Box>
  );
  const isSmallScreen = useSmallScreen();
  return (
    <>
      {menuItems.length > 0 ? (
        <div>
          <StyledSideBarButton onClick={toggleDrawer(state)}>
            <MenuIcon />
          </StyledSideBarButton>
          {isSmallScreen && (
            <SwipeableDrawer
              anchor="left"
              open={state}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              <div>{list()}</div>
            </SwipeableDrawer>
          )}
          {!isSmallScreen && (
            <SwipeableDrawer
              anchor="left"
              variant="permanent"
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              <div>{list()}</div>
            </SwipeableDrawer>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
