import { useTranslation } from "react-i18next";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { ITasks } from "./MyPatientTasks.type";
import { useSelector } from "react-redux";
import {
  useCreateTaskMutation,
  useTasksQuery,
} from "../../redux/api/ByDoctorTaskApi";
import { useEffect, useRef, useState } from "react";
import {
  StyledButton,
  StyledDiv,
  StyledTextArea,
} from "./MyPatientTasks.style";
import {
  Button,
  Checkbox,
  Container,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { dispatchSnackBar } from "../../core/utils/helpers/dispatchSnackBar";
import checked from "../../images/checked.svg";
import notChecked from "../../images/notChecked.svg";

export const MyPatientTasks = (props: ITasks) => {
  const { t, i18n } = useTranslation();
  const token = useSelector(selectCurrentToken) as string;
  const [create, { isError, isSuccess }] = useCreateTaskMutation();
  const { data: tasks } = useTasksQuery({
    token: token,
    patient_id: props.patientId,
    date: props.date,
    lang: i18n.language.substring(0, 2),
  });
  const [tasksValue, setTasksValue] = useState("");
  const [inputValue, setInputValue] = useState(tasksValue);

  const [error, setError] = useState("");
  useEffect(() => {
    if (tasks) {
      setTasksValue(
        Array.isArray(tasks?.tasks) &&
          tasks?.tasks?.map((task: any) => task.content).join("\n")
      );
    }
  }, [tasks]);

  const handleSubmit = async () => {
    const created = await create({
      token: token,
      date: props.date,
      patient_id: props.patientId,
      tasks: tasksValue,
      lang: i18n.language.substring(0, 2),
    })
      .unwrap()
      .then(() => setIsDoubleClick(false))
      .catch((err) => {
        setError(err?.data?.date[0]);
        setIsDoubleClick(false);
      });
  };
  useEffect(() => {
    if (isSuccess || isError) {
      dispatchSnackBar(
        isSuccess,
        isError,
        t("snackbar_messages.success_create_task"),
        error.length > 0 ? error : t("snackbar_messages.error_create_task")
      );
    }
  }, [isSuccess, isError, error]);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(
        textAreaRef.current.value.length,
        textAreaRef.current.value.length
      );
    }
  }, [tasksValue]);

  const Create = () => {
    return (
      <>
        <StyledTextArea
          ref={textAreaRef}
          value={tasksValue}
          onChange={(e) => {
            setTasksValue(e.target.value);
          }}
          autoFocus
        />

        <StyledDiv>
          <StyledButton onClick={handleSubmit}>save</StyledButton>
        </StyledDiv>
      </>
    );
  };
  const [isDoubleClick, setIsDoubleClick] = useState(false);
  const handleDoubleClick = () => {
    setIsDoubleClick(true);
  };
  const Listing = () => {
    return (
      <>
        <Container
          sx={{
            backgroundColor: "white",
            marginTop: "2rem",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onDoubleClick={handleDoubleClick}
        >
          {tasks?.tasks?.length > 0
            ? Array.isArray(tasks?.tasks) &&
              tasks?.tasks?.map((task: any) => (
                <ListItem
                  key={task.id}
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <Checkbox
                        checked={task?.complete == "1"}
                        icon={
                          <img
                            src={notChecked}
                            alt={t("uncompleted") as string}
                          />
                        }
                        checkedIcon={
                          <img src={checked} alt={t("completed") as string} />
                        }
                      />
                    </IconButton>
                  }
                >
                  <ListItemText primary={task.content} />
                </ListItem>
              ))
            : t("no_tasks_yet")}
        </Container>
        {t("double_click")}
      </>
    );
  };
  const content = props.isCreate || isDoubleClick ? <Create /> : <Listing />;
  return <>{content}</>;
};
