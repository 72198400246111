import { useState } from "react";
import {
  LinksContainer,
  ResponsiveStyledList,
  StyledLi,
  StyledList,
  StyledNav,
  StyledNavLink,
  TitleContainer,
} from "./Navbar.style";
import { Sidebar } from "../Sidebar/Sidebar";
import { LangSelect } from "../LangSelect/LangSelect";
import { useTranslation } from "react-i18next";
import { PATHS } from "../../core/constants/paths";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/slices/UserAuth";
import { Patient } from "../../core/models/patient.interface";
import { Doctor } from "../../core/models/doctor.interface";
import ResponsiveNav from "../ResponsiveNav/ResponsiveNav";
import useSmallScreen from "../../hooks/useSmallScreen";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export const Navbar = () => {
  const { t } = useTranslation();
  const userAutenticated = useSelector(selectCurrentUser);
  const [user, setUser] = useState(userAutenticated as Patient | Doctor | null);
  const isSmallScreen = useSmallScreen();
  return (
    <StyledNav>
      <TitleContainer>{user && <Sidebar />}</TitleContainer>
      <LinksContainer>
        {!user && (
          <>
            <ResponsiveStyledList>
              {window.location.pathname !== PATHS.HOME && (
                <StyledLi>
                  <StyledNavLink to={PATHS.HOME}>
                    <ArrowBackIcon />
                    {t("back_to_home_page")}
                  </StyledNavLink>
                </StyledLi>
              )}
              <StyledLi>
                <StyledNavLink to={PATHS.SIGNIN}>
                  {t("auth.signin")}
                </StyledNavLink>
              </StyledLi>
              <StyledLi>
                <StyledNavLink to={PATHS.SIGNUP}>
                  {t("auth.signup")}
                </StyledNavLink>
              </StyledLi>
              <StyledLi>
                <StyledNavLink to={PATHS.PATIENT_AUTH}>
                  {t("auth.patient_auth")}
                </StyledNavLink>
              </StyledLi>
            </ResponsiveStyledList>
            {isSmallScreen && (
              <StyledList>
                <StyledLi>
                  <ResponsiveNav />
                </StyledLi>
              </StyledList>
            )}
          </>
        )}
        <LangSelect />
      </LinksContainer>
    </StyledNav>
  );
};
export default Navbar;
