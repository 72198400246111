import { AppLayout } from "../../layouts/AppLayout/AppLayout";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  DescriptionBox,
  MainBox,
  ButtonBox,
  Item,
  ContainerGrid,
} from "./HomePage.style";
import { ButtonComponent } from "../../components/ButtonComponent/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { HomePageConstants } from "./HomePage.constants";
import useLoggedIn from "../../hooks/useLoggedIn";
import { useSelector } from "react-redux";
import { selectCurrentRole } from "../../redux/slices/UserAuth";
import { DoctorHomePage } from "../DoctorHomePage/DoctorHomePage";
import { PatientHomePage } from "../PatientHomePage/PatientHomePage";
import { GlobalVlariables } from "../../core/constants/constant";
export const HomePage = () => {
  const navigate = useNavigate();
  const ELEMENTS = HomePageConstants();
  const isLoggedIn = useLoggedIn();
  const role = useSelector(selectCurrentRole);
  const content =
    role === GlobalVlariables.ROLE.PATIENT ? (
      <PatientHomePage />
    ) : (
      <DoctorHomePage />
    );
  return (
    <AppLayout isAuth={!isLoggedIn}>
      <Box
        sx={{
          flexGrow: 1,
          margin: "0",
        }}
      >
        {isLoggedIn ? (
          content
        ) : (
          <ContainerGrid container spacing={2}>
            {ELEMENTS.map((element, index) => (
              <Grid item container xs={12} md={4} key={index}>
                <Item>
                  <MainBox>
                    <img src={element.image} />
                    <DescriptionBox>{element.description}</DescriptionBox>
                    <ButtonBox>
                      <ButtonComponent
                        title={element.buttonTitle}
                        onClick={() => navigate(element.link)}
                      />
                    </ButtonBox>
                  </MainBox>
                </Item>
              </Grid>
            ))}
          </ContainerGrid>
        )}
      </Box>
    </AppLayout>
  );
};
