import * as React from "react";
import Menu from "@mui/material/Menu";
import { PATHS } from "../../core/constants/paths";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import {
  StyledButtonIcon,
  StyledConnexionLink,
  StyledMenuItem,
} from "./ResponsiveNav.style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function ResponsiveNav() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  return (
    <div>
      <StyledButtonIcon
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </StyledButtonIcon>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {window.location.pathname !== PATHS.HOME && (
          <StyledMenuItem onClick={handleClose}>
            <StyledConnexionLink to={PATHS.HOME}>
              <ArrowBackIcon fontSize="small" />
              {t("back_to_home_page")}
            </StyledConnexionLink>
          </StyledMenuItem>
        )}

        <StyledMenuItem onClick={handleClose}>
          <StyledConnexionLink to={PATHS.SIGNIN}>
            {t("auth.signin")}
          </StyledConnexionLink>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClose}>
          <StyledConnexionLink to={PATHS.SIGNUP}>
            {t("auth.signup")}
          </StyledConnexionLink>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClose}>
          <StyledConnexionLink to={PATHS.PATIENT_AUTH}>
            {t("auth.patient_auth")}
          </StyledConnexionLink>
        </StyledMenuItem>
      </Menu>
    </div>
  );
}
