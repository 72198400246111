import { TextField, styled } from "@mui/material";

export const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "30px",
    paddingLeft: "2rem",
    "&.Mui-focused fieldset": {
      borderColor: "#a9b9a6d6",
    },
    "&.Mui-focused ": {
      borderColor: "#a9b9a6d6",
    },
  },
});
