import React, { useEffect, useRef } from "react";
import { InputBase } from "@mui/material";
import {
  StyledChildenContainer,
  StyledSpan,
  StyledTextContainer,
  StyledTextField,
} from "./TextFieldComponent.style";
import { IText } from "./TextFieldComponent.type";
import { COLORS } from "../../core/constants/colors";

export const TextFieldComponent = (props: IText) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.autofocus]);

  return (
    <StyledTextContainer noMarginTop={props.noMarginTop}>
      <label>{props.title}</label>
      <StyledTextField
        widthProp={props.width}
        backgroundColor={props.backgroundColor}
      >
        <InputBase
          name={props.name}
          value={props.value}
          type={props.type}
          fullWidth
          onChange={props.onChange}
          inputRef={inputRef}
          autoFocus={props.autofocus}
          disabled={props.disabled}
          inputProps={{ max: "2023-05-17" }}
        />
        <StyledChildenContainer>{props.children}</StyledChildenContainer>
      </StyledTextField>
      <StyledSpan style={{ color: `${COLORS.RED}` }}>
        {props.validation}
      </StyledSpan>
    </StyledTextContainer>
  );
};
