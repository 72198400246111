import { styled, Container } from "@mui/material";
import { COLORS } from "../../core/constants/colors";
import { IAppLayout } from "./AppLayout.type";

export const StyledDiv = styled("div")<IAppLayout>(({ isAuth }) => ({
  minHeight: "100%",
  backgroundColor: isAuth ? "#ebebebed" : "white",
  //paddingBottom: "2rem",
}));

export const StyledContainer = styled(Container)({
  height: "5rem",
  width: "90%",
  borderRadius: "0.5rem",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  backgroundColor: `${COLORS.GREEN.DUSTY_GREEN}`,
  color: `${COLORS.WHITE}`,
  fontSize: "1.5em",
  fontWeight: "bold",
});
