import React, { useCallback, useState } from "react";
import { Alert, Snackbar as SnackbarComponent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import { hide } from "../../redux/slices/SnackBarSlice";
import {
  POSITION_HORIZONTAL,
  POSITION_VERTICAL,
  SNACK_BAR_DURATION,
} from "./SnackBar.constants";

export const SnackBar = () => {
  // get redux snack bar state
  const snackBar = useSelector((state: RootState) => state.snackBar);
  // get dispatch hooks
  const dispatch = useDispatch();
  // handle close snack bar
  const handleClose = useCallback(() => {
    dispatch(hide());
  }, []);

  return (
    <SnackbarComponent
      open={snackBar.isopen}
      autoHideDuration={SNACK_BAR_DURATION}
      onClose={handleClose}
      anchorOrigin={{
        vertical: POSITION_VERTICAL,
        horizontal: POSITION_HORIZONTAL,
      }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={snackBar.color}
      >
        {snackBar.message}
      </Alert>
    </SnackbarComponent>
  );
};
