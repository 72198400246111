import React from "react";
import { StyledButton } from "./ButtonComponent.style";
import { IButton } from "./ButtonComponent.type";

export const ButtonComponent = (props: IButton) => {
  return (
    <StyledButton
      width={props.width}
      type="submit"
      variant="contained"
      onClick={props.onClick}
    >
      {props.title}
    </StyledButton>
  );
};
