import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  getTokenFromLocalStorage,
  isTokenExpired,
} from "../core/utils/storage";
import { useLogoutMutation } from "../redux/api/DoctorAuthApi";
import useDebounce from "./useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectIsLoggedIn } from "../redux/slices/UserAuth";

export default function useLoggedIn() {
  const dispatch = useDispatch();
  const location = useLocation();
  const debouncedLocation = useDebounce(location, 1000);
  useEffect(() => {
    const token = getTokenFromLocalStorage()?.access_token;
    if (token && token.length) {
      if (isTokenExpired()) {
        dispatch(logOut(token));
      }
    } else {
      return;
    }
  }, [debouncedLocation]);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  return isLoggedIn;
}
