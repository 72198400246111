import {
  Button,
  CardActions,
  CardContent,
  Radio,
  TextField,
  styled,
} from "@mui/material";
import { COLORS } from "../../core/constants/colors";
import { IButton } from "../ButtonComponent/ButtonComponent.type";

export const StyledQuestion = styled("div")({
  color: `${COLORS.DARK_PURPLE}`,
  fontWeight: "bold",
  fontSize: "0.8rem",
  justifyContent: "justify!important",
  alignItems: "center",
  textAlign: "center",
  padding: "1rem 0rem 0.5rem 0rem",
});

export const StyledForm = styled("form")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "2rem",
});

export const StyledTextField = styled(TextField)({
  width: "22rem",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: `${COLORS.GREEN.DARK_GREEN_OPACITY_90}`,
    },
    "&.Mui-focused ": {
      borderColor: `${COLORS.GREEN.DARK_GREEN_OPACITY_90}`,
    },
  },
});

export const StyledQuestions = styled(CardContent)({
  maxWidth: "25rem",
  margin: "0rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
});

export const StyledRadio = styled(Radio)({
  "&.MuiRadio-root": {
    "&.Mui-checked": {
      color: `${COLORS.GREEN.DARK_GREEN_OPACITY_90}`,
    },
  },
});
interface IStyledButton {
  isGenerate?: boolean;
}
export const StyledButton = styled(Button)<IStyledButton>(({ isGenerate }) => ({
  fontWeight: "bold",
  color: isGenerate ? `${COLORS.GREEN.DARK_OLIVE_GREEN}` : "inherit",
  maxWidth: "fit-content",
  fontSize: "0.75rem",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const StyledCardActions = styled(CardActions)({
  width: "98%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
