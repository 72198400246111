import { Button, styled } from "@mui/material";
import { IStyledRadioDiv } from "./CreatePatient.type";
import { COLORS } from "../../core/constants/colors";

export const RadioDiv = styled("div")<IStyledRadioDiv>(({ mLeft }) => ({
  width: "80%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginLeft: mLeft ? `${mLeft}` : "0rem!important",
}));

export const StyledGenderRow = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
});
export const OpenPopUpButton = styled(Button)({
  color: `${COLORS.GREEN.DARK_GREEN_OPACITY_75}`,
  borderRadius: "10px",
  border: `2px solid ${COLORS.GREEN.DARK_GREEN_OPACITY_75}`,
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: `${COLORS.GREEN.LIGHT_GREEN}`,
    border: `2px solid ${COLORS.GREEN.DARK_GREEN_OPACITY_75}`,
  },
});
