import { styled, TextField } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "30px",
    "&.Mui-focused fieldset": {
      borderColor: `${COLORS.GREEN.GREENISH_GRAY}`,
    },
    "&.Mui-focused ": {
      borderColor: `${COLORS.GREEN.GREENISH_GRAY}`,
    },
  },
});

export const ColumnDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
});
export const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "0.5rem",
});
