import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TPayload = {
  isopen: boolean;
  color: AlertColor;
  message: string;
};
const initialState = {
  isopen: false,
  message: "",
  color: "success" as Color,
};
export type Color = "success" | "info" | "warning" | "error";
export const snackBarSlice = createSlice({
  name: "snackBar",
  initialState: initialState,
  reducers: {
    show: (state, { payload }: PayloadAction<TPayload>) => {
      state.isopen = true;
      state.color = payload.color;
      state.message = payload.message;
    },
    hide: (state) => {
      state.isopen = false;
    },
  },
});
export const { show, hide } = snackBarSlice.actions;
export const snackBarReducer = snackBarSlice.reducer;
