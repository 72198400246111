import { Link, styled } from "@mui/material";

export const StyledContainer = styled("div")({
  width: "25rem",
  paddingLeft: "0.5rem",
  display: "flex",
  flexDirection: "column",
});
export const StyledLinkContainer = styled("div")({
  width: "100%",
  textAlign: "end",
});
export const StyledLink = styled(Link)({});
