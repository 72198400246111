import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledAccordion = styled(Accordion)({
  marginBottom: "0.5rem",
  borderRadius: "12px",

  boxShadow: ` 0px 2px 1px -1px ${COLORS.GRAY.GRAY_OPACITY_20}, 0px 1px 1px 0px ${COLORS.GRAY.GRAY_OPACITY_14}, 0px 1px 3px 0px ${COLORS.GRAY.GRAY_OPACITY_12}`,

  "&:last-child": {
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    borderBottom: `1px solid ${COLORS.GRAY.GRAY_LIGHTER}`,
  },
  "&:first-of-type": {
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    borderTop: `1px solid ${COLORS.GRAY.GRAY_LIGHTER}`,
  },
});

export const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: `${COLORS.GRAY.GRAY_LIGHTER}`,
  border: "none",
  borderRadius: "12px",
  "&.Mui-expanded": {
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  border: "0px",
});

export const StyledDate = styled("div")({
  fontFamily: "Roboto,Helvetica,Arial,sans-serif",
  fontSize: "16px",
});
export const StyledTitle = styled("div")({
  color: `${COLORS.BROWN}`,
  fontWeight: "400",
  fontFamily: "Roboto,Helvetica,Arial,sans-serif",
  lineHeight: "1.5",
  letterSpacing: "0.00938em",
  fontSize: "16px",
});
export const CriteriaTitle = styled("div")({
  fontSize: "14px",
  color: `${COLORS.GRAY.GRAY_DARK}`,
});
export const CriteriaValue = styled("div")({
  fontSize: "14px",
  color: `${COLORS.GRAY.GRAY_DARK}`,
});
