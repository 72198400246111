import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import { MenuItem } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: ` 0px 2px 1px -1px ${COLORS.GRAY.GRAY_OPACITY_20}, 0px 1px 1px 0px ${COLORS.GRAY.GRAY_OPACITY_14}, 0px 1px 3px 0px ${COLORS.GRAY.GRAY_OPACITY_12}`,
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)({
  fontSize: "13px",
  color: `${COLORS.BLACK_OPACITY_54}`,
});
