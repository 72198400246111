import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { ICount, IDate } from "./DatePickerInput.type";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { useFollowupsCountQuery } from "../../redux/api/FollowUpApi";
import { useEffect } from "react";

const initialValue = dayjs();

function ServerDay(
  props: PickersDayProps<Dayjs> & {
    highlightedDays?: { day: string; badge: string }[];
  }
) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const highlightedDay = highlightedDays.find(
    (d) => d.day === day.format("YYYY-MM-DD")
  );

  const badgeContent = highlightedDay ? (
    <div style={{ fontSize: "0.6rem" }}>{highlightedDay.badge}</div>
  ) : undefined;

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={badgeContent}
      color="secondary"
      sx={{ zIndex: "10" }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export const DatePickerInput = (props: IDate) => {
  const token = useSelector(selectCurrentToken) as string;
  //for every onChangeMonth call the query to get the number of appointments of each day of the month selected
  const [month, setMonth] = React.useState(dayjs().format("MM"));
  const [year, setYear] = React.useState(dayjs().format("YYYY"));
  const { data: followups } = useFollowupsCountQuery({
    token: token,
    month: month,
    year: year,
  });
  const [highlightedDays, setHighlightedDays] = React.useState<
    { day: string; badge: number }[]
  >([]);
  useEffect(() => {
    if (followups?.follow_ups && Array.isArray(followups?.follow_ups)) {
      setHighlightedDays(
        followups?.follow_ups?.map((followup: ICount) => ({
          day: dayjs(followup.date).format("YYYY-MM-DD"),
          badge: followup.followup_count,
        }))
      );
    }
  }, [followups]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        sx={{ width: "auto" }}
        defaultValue={initialValue}
        // showDaysOutsideCurrentMonth
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          } as any,
        }}
        onChange={props.handleChangeDate}
        onYearChange={(date) => {
          setYear(date.format("YYYY"));
        }}
        onMonthChange={(date) => {
          setMonth(date.format("MM"));
        }}
      />
    </LocalizationProvider>
  );
};
