import { store } from "../../../redux/store";
import { show } from "../../../redux/slices/SnackBarSlice";
export const dispatchSnackBar = (
  success: boolean,
  error: any,
  successMessage: string,
  errorMessage: string
) => {
  if (success) {
    store.dispatch(
      show({
        color: "success",
        isopen: true,
        message: successMessage,
      })
    );
  } else if (error) {
    store.dispatch(
      show({
        color: "error",
        isopen: true,
        message: errorMessage,
      })
    );
  }
};
