import { useEffect, useRef, useState } from "react";
import { AppLayout } from "../../layouts/AppLayout/AppLayout";
import {
  CentredTable,
  StyledContainer,
  StyledDiv,
  Title,
} from "./Calendar.style";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { Container, Divider, Paper, TableContainer } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFollowupsQuery } from "../../redux/api/FollowUpApi";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/slices/UserAuth";
import { IAppointment, IEvent } from "./Calendar.type";
import { EventDropArg, EventResizeDoneArg } from "fullcalendar";
import { UpdateFollowup } from "../../components/UpdateFollowup/UpdateFollowup";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../core/constants/paths";
import { getRandomColor } from "../../core/utils/helpers/getRandomColor";
import { COLORS } from "../../core/constants/colors";

export const Calendar = () => {
  const token = useSelector(selectCurrentToken) as string;
  const { t, i18n } = useTranslation();
  const [viewStart, setViewStart] = useState("");
  const [viewEnd, setViewEnd] = useState("");
  const { data, isLoading } = useFollowupsQuery({
    token: token,
    date: viewStart,
    to: viewEnd,
  });

  const [appointments, setAppointments] = useState<IEvent[]>([]);
  useEffect(() => {
    if (data?.follow_ups?.length > 0 && Array.isArray(data.follow_ups)) {
      setAppointments(
        data.follow_ups.map((appointment: IAppointment, index: number) => ({
          title: appointment.first_name + " " + appointment.last_name,
          start: appointment.date + "T" + appointment.start_time,
          end: appointment.date + "T" + appointment.end_time,

          color: getRandomColor(),
          id: appointment.id,
          patient_id: appointment.patient_id,
        }))
      );
    }
  }, [data]);

  const calendarRef = useRef<any>(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const initialEvent = {
    followUpId: -1,
    date: "",
    start: "",
    end: "",
  };
  const [event, setEvent] = useState(initialEvent);
  const onClose = () => {
    setOpenUpdateModal(false);
    setEvent(initialEvent);
  };
  const updateEvent = (e: EventDropArg | EventResizeDoneArg) => {
    setEvent({
      followUpId: parseInt(e?.event?._def?.publicId),
      date: e?.event?._instance?.range?.start
        ? new Date(e?.event?._instance?.range?.start)
            .toISOString()
            .split("T")[0]
        : "",
      start: e?.event?._instance?.range?.start
        ? new Date(e?.event?._instance?.range?.start)
            .toISOString()
            .split("T")[1]
            .split(":")
            .slice(0, 2)
            .join(":")
        : "",
      end: e?.event?._instance?.range?.end
        ? new Date(e?.event?._instance?.range?.end)
            .toISOString()
            .split("T")[1]
            .split(":")
            .slice(0, 2)
            .join(":")
        : "",
    });
    setOpenUpdateModal(true);
  };
  const navigate = useNavigate();
  return (
    <AppLayout>
      <CentredTable>
        <StyledDiv>
          <StyledContainer>
            <Title>Calendar</Title>
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <TableContainer
                sx={{
                  borderRadius: "1.5rem",
                  padding: "2rem ",
                  backgroundColor: `${COLORS.WHITE} `,
                }}
              >
                <FullCalendar
                  themeSystem="Materia"
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    listPlugin,
                    interactionPlugin,
                  ]}
                  initialView={"dayGridMonth"}
                  headerToolbar={{
                    start: "today prev,next",
                    center: "title",
                    end: "dayGridMonth,timeGridWeek,timeGridDay,list",
                  }}
                  firstDay={1}
                  buttonText={{
                    today: t("today") as string,
                    month: t("month") as string,
                    week: t("week") as string,
                    day: t("day") as string,
                    list: t("list") as string,
                  }}
                  datesSet={(arg) => {
                    setViewStart(
                      new Date(arg.start).toISOString().substring(0, 10)
                    );
                    setViewEnd(
                      new Date(arg.end).toISOString().substring(0, 10)
                    );
                  }}
                  locale={i18n.language.substring(0, 2)}
                  slotMinTime="08:00:00"
                  slotMaxTime="19:00:00"
                  height={700}
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  events={appointments}
                  ref={calendarRef}
                  dragScroll={true}
                  eventDrop={(e) => updateEvent(e)}
                  eventResize={(e) => updateEvent(e)}
                  eventClick={(arg) => {
                    navigate(
                      PATHS.MY_PATIENT_PROFILE.replace(
                        ":id",
                        arg?.event?._def?.extendedProps?.patient_id
                      )
                    );
                  }}
                />
              </TableContainer>
            </Container>
          </StyledContainer>
          <UpdateFollowup
            open={openUpdateModal}
            onClose={onClose}
            followUpId={event.followUpId}
            date={event.date}
            start={event.start}
            end={event.end}
            disableDate={true}
          />
        </StyledDiv>
      </CentredTable>
    </AppLayout>
  );
};
