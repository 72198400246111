import { styled, TableCell, TableContainer, TableRow } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledContainer = styled("div")({
  width: "95%",
  padding: "1rem",
  display: "flex",
  justifyContent: "center", // center the content horizontally
  alignItems: "center", // center the content vertically
  textAlign: "center",
  marginRight: "0!important",
});
export const Search = styled("div")({
  width: "14rem",
  display: "flex",
  backgroundColor: `${COLORS.WHITE_OPACITY_95}`,
  padding: "0.5rem",
  borderRadius: "10px",
  justifyContent: "space-between",
  height: "1.5rem",
});
export const TableAction = styled("div")({
  maxWidth: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "2rem 2rem 2rem 0.5rem",
});
export const StyledTableRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: `${COLORS.LIGHT_BLUE_OPACITY}`,
    cursor: "pointer",
  },
});
export const StyledHeadCell = styled(TableCell)({
  fontSize: "0.875 rem!important",
  color: `${COLORS.DARK_PURPLE}!important`,
  height: "1.5rem",
  fontWeight: "bold",
});
export const StyledBodyCell = styled(TableCell)({
  fontSize: "0.8rem!important",
  color: "black !important",
  fontWeight: "550",
});
export const StyledTableContainer = styled(TableContainer)({
  backgroundColor: `${COLORS.GREEN.LIGHTEST_GREEN}`,
  borderRadius: "10px",
  boxShadow: "none",
});

export const Title = styled("div")({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  color: `${COLORS.DARK_PURPLE}`,
  fontSize: "1.5em",
  fontWeight: "bold",
  padding: "1.5rem",
});
