import { MenuItem, styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";

export const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  padding: "1rem",
  width: "fits-content",
  height: "fits-content",
});
export const StyledMenuItem = styled(MenuItem)({
  "&.active": {
    color: `${COLORS.GREEN.OLIVE_GREEN}`,
    fontFamily: "bold",
  },
});
