import React, { ReactNode } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { SnackBar } from "../../components/SnackBar/SnackBar";
import { Container, styled } from "@mui/material";
import useSmallScreen from "../../hooks/useSmallScreen";
import useLoggedIn from "../../hooks/useLoggedIn";
import { COLORS } from "../../core/constants/colors";
import { StyledContainer, StyledDiv } from "./AppLayout.style";
import { IAppLayout } from "./AppLayout.type";

export const AppLayout = (props: IAppLayout) => {
  const isSmallScreen = useSmallScreen();
  const isLoggedIn = useLoggedIn();
  return (
    <StyledDiv isAuth={props.isAuth}>
      <Navbar />
      <div
        style={{
          height: "calc(100% - 5rem)",
          marginLeft: isSmallScreen || !isLoggedIn ? "0" : "16rem",
        }}
      >
        <SnackBar />
        {props.children}
      </div>
    </StyledDiv>
  );
};
