import React, { ReactNode, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Grid, Stack } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Item, StyledButton, StyledTitle } from "./WeekCalendar.style";
import { selectCurrentRole } from "../../redux/slices/UserAuth";
import { useSelector } from "react-redux";
import "dayjs/locale/fr";
import { useTranslation } from "react-i18next";
import { MyPatientTasks } from "../MyPatientTasks/MyPatientTasks";
import { Tasks } from "../Tasks/Tasks";
import { IWeekCalendar } from "./WeekCalendar.type";
import { GlobalVlariables } from "../../core/constants/constant";
export const WeekCalendar = (props: IWeekCalendar) => {
  const { t, i18n } = useTranslation();

  const [currentDate, setCurrentDate] = useState(dayjs());
  const role = useSelector(selectCurrentRole);

  const goToPreviousWeek = () => {
    setCurrentDate(currentDate.subtract(1, "week"));
  };

  const goToNextWeek = () => {
    setCurrentDate(currentDate.add(1, "week"));
  };

  let startOfWeek = currentDate.startOf("week");
  let endOfWeek = currentDate.endOf("week");

  let weekDays: Dayjs[] = [];

  for (let day = startOfWeek; day <= endOfWeek; day = day.add(1, "day")) {
    weekDays.push(day);
  }
  const [weekDaysState, setWeekDaysState] = useState<Dayjs[]>(weekDays);
  const [clickedDay, setClickedDay] = useState(
    currentDate.format("YYYY-MM-DD")
  );
  useEffect(() => {
    dayjs.locale(i18n.language.substring(0, 2));
  }, [i18n.language]);

  const [tasks, setTasks] = useState<ReactNode>(null);

  useEffect(() => {
    const tasksComponent =
      role == GlobalVlariables.ROLE.DOCTOR ? (
        <MyPatientTasks
          patientId={props.patientId ? props.patientId : -1}
          date={clickedDay}
          isCreate={props.isCreate}
        />
      ) : (
        <Tasks date={clickedDay} />
      );
    setTasks(tasksComponent);
  }, [clickedDay]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6}>
          <StyledTitle>
            {startOfWeek.format("MMMM D")} - {endOfWeek.format("MMMM D")}
          </StyledTitle>
        </Grid>
        <Grid item xs={2} md={2}>
          <StyledButton onClick={goToPreviousWeek}>
            <ArrowBackIosNewIcon />
          </StyledButton>
        </Grid>
        <Grid item xs={2} md={2}>
          <StyledButton onClick={goToNextWeek}>
            <ArrowForwardIosIcon />
          </StyledButton>
        </Grid>

        <Grid item xs={12} md={12}>
          <Stack direction="row" spacing={{ xs: 1, md: 2 }}>
            {weekDays.map((day) => (
              <Item
                key={day.format("dddd, MMMM D")}
                className={
                  clickedDay === day.format("YYYY-MM-DD") ? "active" : ""
                }
                onClick={() => {
                  setClickedDay(day.format("YYYY-MM-DD"));
                }}
              >
                {day.format("dddd, MMMM D")}
              </Item>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          {tasks}
        </Grid>
      </Grid>
    </div>
  );
};
