import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PATIENT_BASE_URL, ENDPOINTS } from "../../core/constants/paths";

export const openAiApi = createApi({
  reducerPath: "openAiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: PATIENT_BASE_URL,
  }),
  endpoints: (builder) => ({
    program: builder.query({
      query: (parameters: {
        height: number;
        weight: number;
        age: number;
        gender: string;
        days?: string[];
        meals_to_avoid?: string;
        goals: string[];
        allergy?: string;
        illness?: string[];
        pregnant: string;
        lang?: string;
      }) => ({
        url: ENDPOINTS.GENERATE_PROGRAM,
        method: "POST",
        body: {
          infos: {
            height: parameters.height,
            weight: parameters.weight,
            age: parameters.age,
            gender: parameters.gender,
            pregnant: parameters.pregnant,
          },
          goal: parameters.goals,
          allergies: parameters.allergy,
          illnesses: parameters.illness,
          available: parameters.days,
          meals_to_avoid: parameters.meals_to_avoid,
          lang: parameters.lang,
        },
      }),
    }),
    sendProgram: builder.mutation({
      query: (params: {
        email: string;
        inputs: string;
        program: string;
        lang?: string;
      }) => ({
        url: ENDPOINTS.SEND_PROGRAM,
        method: "POST",
        body: {
          email: params.email,
          inputs: params.inputs,
          program: params.program,
          lang: params.lang,
        },
      }),
    }),
  }),
});
export const { useProgramQuery, useSendProgramMutation } = openAiApi;
