import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS, PATIENT_BASE_URL } from "../../core/constants/paths";

export const byPatientFollowupApi = createApi({
  reducerPath: "byPatientFollowupApi",
  baseQuery: fetchBaseQuery({
    baseUrl: PATIENT_BASE_URL,
  }),
  tagTypes: ["followups"],
  endpoints: (builder) => ({
    History: builder.query({
      query: (parameters: { token: string; lang?: string }) => ({
        url: `/${ENDPOINTS.PROFILE}/${ENDPOINTS.HISTORY}`,
        method: "GET",
        params: {
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      providesTags: ["followups"],
    }),

    Details: builder.query({
      query: (parameters: {
        token: string;
        followup_id: number;
        lang?: string;
      }) => ({
        url: `${ENDPOINTS.FETCH_FOLLOWUPS}/${parameters.followup_id}`,
        method: "GET",
        params: {
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      providesTags: ["followups"],
    }),
    Tracking: builder.query({
      query: (parameters: { token: string; lang?: string }) => ({
        url: `/${ENDPOINTS.PROFILE}/${ENDPOINTS.TRACKING}`,
        method: "GET",
        params: {
          lang: parameters.lang,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + parameters.token,
        },
      }),
      providesTags: ["followups"],
    }),
  }),
});
export const { useTrackingQuery, useDetailsQuery, useHistoryQuery } =
  byPatientFollowupApi;
