import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { NotApprovedComponent } from "../../components/notApprovedComponent/NotApprovedComponent";
import { Doctor } from "../../core/models/doctor.interface";
import useLoggedIn from "../../hooks/useLoggedIn";
import {
  selectCurrentRole,
  selectCurrentUser,
} from "../../redux/slices/UserAuth";
import { GlobalVlariables } from "../../core/constants/constant";
import { PATHS } from "../../core/constants/paths";

interface Props {
  children: ReactNode;
}

function DoctorGuard(props: Props) {
  const { children } = props;
  const isLoggedIn = useLoggedIn();
  const { t } = useTranslation();
  const user = useSelector(selectCurrentUser);
  const role = useSelector(selectCurrentRole);
  if (user && "is_approved" in user) {
    const isApproved = (user as Doctor).is_approved;
    if (role == GlobalVlariables.ROLE.DOCTOR && isApproved != 1) {
      return (
        <NotApprovedComponent title={t("not_approved.not_yet") || undefined} />
      );
    }
  }
  if (!isLoggedIn) {
    return <Navigate to={PATHS.SIGNIN} replace />;
  }
  if (role != GlobalVlariables.ROLE.DOCTOR) {
    return <Navigate to={PATHS.HOME} replace />;
  }
  return <>{children}</>;
}

export default DoctorGuard;
