import { useTranslation } from "react-i18next";

export const OpenAiFormConstants = () => {
  const { t } = useTranslation();
  const STEPS = [
    {
      title: t("guest_form.steps.basic_informations"),
      questions: [
        {
          type: "textfield",
          question: t("guest_form.questions.height"),
          name: "height",
        },
        {
          type: "textfield",
          question: t("guest_form.questions.weight"),
          name: "weight",
        },
        {
          type: "textfield",
          question: t("guest_form.questions.age"),
          name: "age",
        },
        {
          type: "select",
          question: t("guest_form.questions.gender"),
          options: [
            { text: t("guest_form.options.gender.male"), value: "male" },
            { text: t("guest_form.options.gender.female"), value: "female" },
          ],
          name: "gender",
        },
      ],
    },
    {
      title: t("guest_form.steps.goal_&_flexability"),
      questions: [
        {
          type: "checkbox",
          question: t("guest_form.questions.available_days_for_sport"),
          options: [
            { text: t("guest_form.options.days.monday"), value: "monday" },
            { text: t("guest_form.options.days.tuesday"), value: "tuesday" },
            {
              text: t("guest_form.options.days.wednesday"),
              value: "wednesday",
            },
            { text: t("guest_form.options.days.thursday"), value: "thursday" },
            { text: t("guest_form.options.days.friday"), value: "friday" },
            { text: t("guest_form.options.days.saturday"), value: "saturday" },
            { text: t("guest_form.options.days.sunday"), value: "sunday" },
          ],
          name: "days",
        },
        {
          type: "textfield",
          question: t("guest_form.questions.meals_to_avoid"),
          name: "meals_to_avoid",
        },
        {
          type: "checkbox",
          question: t("guest_form.questions.goals"),
          options: [
            {
              text: t("guest_form.options.goals.healthy_weight"),
              value: "Achieving and maintaining a healthy weight",
            },
            {
              text: t("guest_form.options.goals.improve_cardiovascular"),
              value: "Improve cardiovascular fitness and endurance.",
            },
            {
              text: t("guest_form.options.goals.muscle_strength"),
              value: "Build muscle strength and improve body composition.",
            },
            {
              text: t("guest_form.options.goals.Reduce_stress"),
              value: "Reduce stress and improve mental well-being.",
            },

            {
              text: t("guest_form.options.goals.improve_sleep"),
              value:
                "Improve sleep quality and establish a consistent sleep routine.",
            },
            {
              text: t("guest_form.options.goals.balaced_diet"),
              value: "Adopt a balanced and nutritious diet for overall health.",
            },
            {
              text: t("guest_form.options.goals.healthy_immune_system"),
              value: "Maintain a healthy immune system and prevent illness.",
            },
            {
              text: t("guest_form.options.goals.abdominals"),
              value:
                "Strengthen and tone the abdominal muscles, sculpting a defined and chiseled midsection.",
            },
            {
              text: t("guest_form.options.goals.arms"),
              value:
                "Build well-defined biceps, triceps, and overall arm muscles, creating sculpted and strong arms.",
            },
            {
              text: t("guest_form.options.goals.legs"),
              value:
                "Develop toned and shapely legs by targeting and strengthening the muscles of the thighs, calves, and glutes",
            },
          ],
          name: "goals",
        },
      ],
    },
    {
      title: t("guest_form.steps.need_to_be_aware_of"),
      questions: [
        {
          type: "textfield",
          question: t("guest_form.questions.allergy"),
          name: "allergy",
        },
        {
          type: "checkbox",
          question: t("guest_form.questions.illness"),
          options: [
            {
              text: t("guest_form.options.illnesses.heart_disease"),
              value: "Heart Disease",
            },
            {
              text: t("guest_form.options.illnesses.asthma"),
              value: "Asthma",
            },
            {
              text: t("guest_form.options.illnesses.hypertension"),
              value: "Hypertension",
            },
            {
              text: t("guest_form.options.illnesses.diabetes"),
              value: "Diabetes",
            },
          ],
          name: "illness",
        },
        {
          type: "select",
          question: t("guest_form.questions.pregnant"),
          options: [
            {
              text: t("yes"),
              value: "yes",
            },
            {
              text: t("no"),
              value: "no",
            },
          ],
          name: "pregnant",
        },
      ],
    },
    {
      title: t("guest_form.steps.generate"),
      questions: [],
    },
  ];

  return STEPS;
};
