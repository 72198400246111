import { useState } from "react";
import { AppLayout } from "../../layouts/AppLayout/AppLayout";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { Box, Container, Grid } from "@mui/material";
import { PersonalInformations } from "../../components/PersonalInformations/PersonalInformations";
import { ProfilMenu } from "../../components/ProfilMenu/ProfilMenu";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentRole } from "../../redux/slices/UserAuth";
import { MyPatientInfo } from "../../components/MyPatientInfo/MyPatientInfo";
import { MyPatientHistory } from "../../components/MyPatientHistory/MyPatientHistory";
import { History } from "../../components/History/History";
import { COLORS } from "../../core/constants/colors";
import { WeekCalendar } from "../../components/WeekCalendar/WeekCalendar";
import { TrackingChart } from "../../components/TrackingChart/TrackingChart";
import { MyPatientTracking } from "../../components/MyPatientTracking/MyPatientTracking";
import { GlobalVlariables } from "../../core/constants/constant";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? `${COLORS.GRAY.BLUE_GRAY}`
      : `${COLORS.WHITE}`,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "start",
  color: theme.palette.text.secondary,
}));

export const PatientProfil = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(1);
  const handleMenuItemClick = (menuItem: number) => {
    setActiveMenuItem(menuItem);
  };
  const [name, setName] = useState("");
  const setPatientName = (name: string) => {
    setName(name);
  };
  const [gender, setGender] = useState(-1);
  const setPatientGender = (gender: number) => {
    setGender(gender);
  };
  const { id } = useParams();
  let content;
  const role = useSelector(selectCurrentRole);
  if (activeMenuItem === 1) {
    content =
      role === GlobalVlariables.ROLE.PATIENT ? (
        <PersonalInformations
          setPatientName={setPatientName}
          setPatientGender={setPatientGender}
        />
      ) : (
        <MyPatientInfo
          setPatientName={setPatientName}
          setPatientGender={setPatientGender}
          patientId={id ? parseInt(id) : -1}
        />
      );
  } else if (activeMenuItem === 2) {
    content =
      role === GlobalVlariables.ROLE.PATIENT ? (
        <History />
      ) : (
        <MyPatientHistory patientId={id ? parseInt(id) : -1} />
      );
  } else if (activeMenuItem === 4) {
    content = <WeekCalendar patientId={id ? parseInt(id) : -1} />;
  } else if (activeMenuItem === 3) {
    content =
      role === GlobalVlariables.ROLE.PATIENT ? (
        <TrackingChart />
      ) : (
        <MyPatientTracking patientId={id ? parseInt(id) : -1} />
      );
  }
  return (
    <AppLayout isAuth={true}>
      <Container
        sx={{
          height: "42rem",
          display: "flex",
          paddingTop: "3rem",
          textAlign: "start",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            height: "fit-content",
            width: "100%",
            overflow: "auto",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Item>
                <ProfilMenu
                  onItemClick={handleMenuItemClick}
                  activeItem={activeMenuItem}
                  patientName={name}
                  patientGender={gender}
                />
              </Item>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Item
                sx={{
                  overflow: "auto",
                  backgroundColor:
                    activeMenuItem === 2
                      ? "#ebebebed"
                      : `${COLORS.GRAY.GRAY_LIGHTER}`,
                  paddingTop: activeMenuItem === 2 ? "0" : "8px",
                  boxShadow:
                    activeMenuItem === 2
                      ? "none"
                      : ` 0px 2px 1px -1px ${COLORS.GRAY.GRAY_OPACITY_20}, 0px 1px 1px 0px ${COLORS.GRAY.GRAY_OPACITY_14}, 0px 1px 3px 0px ${COLORS.GRAY.GRAY_OPACITY_12}`,
                }}
              >
                {content}
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </AppLayout>
  );
};
