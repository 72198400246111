import react from "react";
import { ButtonBase, Dialog, DialogContent } from "@mui/material";
import { StyledDialogTitle } from "./FormDialog.style";
import { IFormDialog } from "./FormDialog.type";
import CloseIcon from "@mui/icons-material/Close";
export const FormDialog = (props: IFormDialog) => {
  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} scroll="paper">
        <StyledDialogTitle>
          {props.title}{" "}
          <ButtonBase>
            <CloseIcon onClick={props.onClose} />
          </ButtonBase>
        </StyledDialogTitle>

        <DialogContent dividers={true}>{props.children}</DialogContent>
      </Dialog>
    </>
  );
};
