import React from "react";
import { LoaderOverlay, LoaderSpinner, LoaderStyles } from "./Loader.style";

export const Loader = () => {
  return (
    <LoaderOverlay>
      <LoaderSpinner>
        <LoaderStyles />
      </LoaderSpinner>
    </LoaderOverlay>
  );
};
