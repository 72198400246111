import { configureStore } from "@reduxjs/toolkit";
import { doctorAuthApi } from "./api/DoctorAuthApi";
import { patientAuthApi } from "./api/PatientAuthApi";
import { patientApi } from "./api/PatientsApi";
import { userauthReducer } from "./slices/UserAuth";
import { snackBarReducer } from "./slices/SnackBarSlice";
import { followupApi } from "./api/FollowUpApi";
import { byPatientFollowupApi } from "./api/ByPatientFollowUpApi";
import { byPatientTaskApi } from "./api/ByPatientTaskApi";
import { byDoctorTaskApi } from "./api/ByDoctorTaskApi";
import { openAiApi } from "./api/OpenAiAPi";
export const store = configureStore({
  reducer: {
    [doctorAuthApi.reducerPath]: doctorAuthApi.reducer,
    [patientAuthApi.reducerPath]: patientAuthApi.reducer,
    [patientApi.reducerPath]: patientApi.reducer,
    [followupApi.reducerPath]: followupApi.reducer,
    [byPatientFollowupApi.reducerPath]: byPatientFollowupApi.reducer,
    [byPatientTaskApi.reducerPath]: byPatientTaskApi.reducer,
    [byDoctorTaskApi.reducerPath]: byDoctorTaskApi.reducer,
    [openAiApi.reducerPath]: openAiApi.reducer,
    userauth: userauthReducer,
    snackBar: snackBarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      doctorAuthApi.middleware,
      patientAuthApi.middleware,
      patientApi.middleware,
      followupApi.middleware,
      byPatientFollowupApi.middleware,
      byPatientTaskApi.middleware,
      byDoctorTaskApi.middleware,
      openAiApi.middleware,
    ]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
