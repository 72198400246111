import React from "react";
import { useTranslation } from "react-i18next";

export const TableComponentConstants = () => {
  const { t } = useTranslation();
  const HEAD_ARRAY = [
    { value: t("user.first_name"), key: "first_name" },
    { value: t("user.last_name"), key: "last_name" },
    { value: t("user.phone"), key: "phone_number" },
    { value: t("user.birth_date"), key: "birth_date" },
    { value: t("user.gender"), key: "gender" },
    { value: t("user.email"), key: "email" },
    // { value: t("user.patient_since"), key: "created_at" },
    { key: "actions" },
  ];
  const DEFAUL_ORDER_CULUMN = "created_at";
  const DEFAULT_ORDER_TYPE = "desc";

  return {
    HEAD_ARRAY,
    DEFAUL_ORDER_CULUMN,
    DEFAULT_ORDER_TYPE,
  };
};
