import { Button, styled } from "@mui/material";
import { COLORS } from "../../core/constants/colors";
export interface IButton {
  textColor?: string;
}
export const ActionButton = styled(Button)<IButton>(({ textColor }) => ({
  fontWeight: "bold",
  borderRadius: "50px !important",
  color: textColor ? `${textColor}` : `${COLORS.GRAY.DARK_GRAY}`,
  backgroundColor: `${COLORS.LIGHT_BLUE}`,
  height: "fit-content",
  border: "none !important",
  cursor: "pointer",
  textTransform: "none",
  "&:hover": {
    backgroundColor: `${COLORS.GRAY.DARK_GRAY}`,
  },
}));
export const StyledTitle = styled("div")({
  color: `${COLORS.BROWN}`,
  fontWeight: "400",
  fontFamily: "Roboto,Helvetica,Arial,sans-serif",
  lineHeight: "1.5",
  letterSpacing: "0.00938em",
  fontSize: "16px",
});
